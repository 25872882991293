import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { ApiResponse } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { IPurchaseList } from '../../../../interfaces/purchases/IPurchaseList';
import { IPurchase } from '../../../../interfaces/purchases/IPurchase';
import { PurchasesService } from './purchasesApi';


const initialState: IPurchaseList = {
    purchaseIsLoading: false,
    purchasesAreLoading: false,
    purchaseNotesAreLoading: true,
    purchaseContentIsLoading: false,
    mainOrderLoading: false,
    requestStatus: 'default',
    message: '',
};

export const tryToFetchPurchases = createAsyncThunk<
    any,
    IPaginationPayload
>('purchases/tryToFetchPurchases', async ({ pageNumber, pageSize, data }) => {
    const result = await PurchasesService.tryToFetchPurchases(
        pageNumber,
        pageSize,
        data,
    );
    return result?.data;
});

export const tryToFetchSinglePurchase = createAsyncThunk<any, string>(
    'purchases/tryToFetchSinglePurchase',
    async (id: string) => {
        const result = await PurchasesService.tryToFetchSinglePurchase(id);
        return result?.data;
    },
);

export const tryToCreatePurchase = createAsyncThunk<any, IPurchase>(
    'purchases/tryToCreatePurchase',
    async (data) => {
        const result = await PurchasesService.tryToCreatePurchase(data);
        return result?.data;
    },
);

export const tryToAddPurchaseNote = createAsyncThunk<any, any>(
    'purchases/tryToAddPurchaseNote',
    async ({ id, data }) => {
        const result = await PurchasesService.tryToAddPurchaseNote(id, data);
        return result?.data;
    },
);

export const tryToFetchPurchaseNote = createAsyncThunk<any, string>(
    'purchases/tryToFetchPurchaseNote',
    async (id: string) => {
        const result = await PurchasesService.tryToFetchPurchaseNote(id);
        return result?.data;
    },
);

export const tryToEditPurchase = createAsyncThunk<any, { id: string, data: IPurchase }>(
    'purchase/tryToEditPurchase',
    async ({ id, data }, { rejectWithValue }) => {
        try {
            const result = await PurchasesService.tryToEditPurchase(id, data);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const tryToSplitPurchase = createAsyncThunk<any, string>(
    'purchase/tryToSplitPurchase',
    async (id, { rejectWithValue }) => {
        try {
            const result = await PurchasesService.tryToSplitPurchase(id);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const tryToDeletePurchase = createAsyncThunk<ApiResponse<IPurchase>, string>(
    'purchases/tryToDeletePurchase',
    async (id, { rejectWithValue }) => {
        try {
            const result = await PurchasesService.tryToDeletePurchase(id);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const tryToReviewPurchase = createAsyncThunk<any, { id: string, data: any }>(
    'purchase/tryToReviewPurchase',
    async ({ id, data }, { rejectWithValue }) => {
        try {
            const result = await PurchasesService.tryToReviewPurchase(id, data);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const tryToFetchPurchaseContent = createAsyncThunk<any, string>(
    'purchases/tryToFetchPurchaseContent',
    async (id: string) => {
        const result = await PurchasesService.tryToFetchPurchaseContent(id);
        return result?.data;
    },
);

export const tryToFetchPurchasePackages = createAsyncThunk<any, string>(
    'purchases/tryToFetchPurchasePackages',
    async (id: string) => {
        const result = await PurchasesService.tryToFetchPurchasePackages(id);
        return result?.data?.data;
    },
);

export const tryToAddPurchasePackages = createAsyncThunk<any, { id: string, data: any, status: any }>(
    'purchase/tryToAddPurchasePackages',
    async ({ id, data, status }, { rejectWithValue }) => {
        try {
            const result = await PurchasesService.tryToAddPurchasePackages(id, data);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const tryToAcceptPurchase = createAsyncThunk<any, string>(
    'purchase/tryToAcceptPurchase',
    async (id, { rejectWithValue }) => {
        try {
            const result = await PurchasesService.tryToAcceptPurchase(id);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const tryToAwaitCreditPurchaseItem = createAsyncThunk<any, any>(
    'purchase/tryToAwaitCreditPurchaseItem',
    async ({ id, item }, { rejectWithValue }) => {
        try {
            const result = await PurchasesService.tryToAwaitCreditPurchaseItem(id, item);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
)

export const tryToPayPurchase = createAsyncThunk<any, { id: string, dueDate?: string }>(
    'purchase/tryToPayPurchase',
    async ({ id, dueDate }, { rejectWithValue }) => {
        try {
            const result = await PurchasesService.tryToPayPurchase(id, dueDate);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const tryToFetchMainOrder = createAsyncThunk<any, string>(
    'purchases/tryToFetchMainOrder',
    async (id) => {
        const result = await PurchasesService.tryToFetchMainOrder(id);
        return result?.data;
    },
);

export const tryToGetPurchaseUuid = createAsyncThunk<any, any>(
    'purchases/tryToGetPurchaseUuid',
    async () => {
        const result = await PurchasesService.tryToGetPurchaseUuid();
        return result?.data;
    },
);

export const tryToAcceptPurchaseItem = createAsyncThunk<any, { id: string, itemId: string }>(
    'purchase/tryToAcceptPurchaseItem',
    async ({ id, itemId }, { rejectWithValue }) => {
        try {
            const result = await PurchasesService.tryToAcceptPurchaseItem(id, itemId);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const tryToRevaluePurchaseItem = createAsyncThunk<any, { id: string, itemId: string, data: any }>(
    'purchase/tryToRevaluePurchaseItem',
    async ({ id, itemId, data }, { rejectWithValue }) => {
        try {
            const result = await PurchasesService.tryToRevaluePurchaseItem(id, itemId, data);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const tryToReturnPurchaseItem = createAsyncThunk<any, { id: string, itemId: string }>(
    'purchase/tryToReturnPurchaseItem',
    async ({ id, itemId }, { rejectWithValue }) => {
        try {
            const result = await PurchasesService.tryToReturnPurchaseItem(id, itemId);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const tryToMatchPurchaseItem = createAsyncThunk<any, { id: string, itemId: string, data: any }>(
    'purchase/tryToMatchPurchaseItem',
    async ({ id, itemId, data }, { rejectWithValue }) => {
        try {
            const result = await PurchasesService.tryToMatchPurchaseItem(id, itemId, data);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const tryToReturnAllPurchase = createAsyncThunk<any, { id: string, data: any }>(
    'purchase/tryToReturnAllPurchase',
    async ({ id, data }, { rejectWithValue }) => {
        try {
            const result = await PurchasesService.tryToReturnAllPurchase(id, data);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const tryToFinalizePurchaseReview = createAsyncThunk<any, string>(
    'purchase/tryToFinalizePurchaseReview',
    async (id, { rejectWithValue }) => {
        try {
            const result = await PurchasesService.tryToFinalizePurchaseReview(id);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const tryToValidatedImportedItems = createAsyncThunk<any, { assetTemplateId: string, data: any }>(
    'purchase/tryToValidatedImportedItems',
    async ({ assetTemplateId, data }, { rejectWithValue }) => {
        try {
            const result = await PurchasesService.tryToValidatedImportedItems(assetTemplateId, data);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const tryToChangePurchaseStatus = createAsyncThunk<any, { id: string, status: string, data?: any }>(
    'purchase/tryToChangePurchaseStatus',
    async ({ id, status, data }, { rejectWithValue }) => {
        try {
            const result = await PurchasesService.tryToChangePurchaseStatus(id, status, data);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const purchasesSlice = createSlice({
    name: 'purchases',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // tryToFetchPurchases
            .addCase(tryToFetchPurchases.pending, (state) => {
                state.purchasesAreLoading = true;
            })
            .addCase(tryToFetchPurchases.rejected, (state) => {
                state.purchasesAreLoading = false;
            })
            .addCase(tryToFetchPurchases.fulfilled, (state, action) => {
                state.purchasesAreLoading = false;
                state.purchases = action.payload;
            })

            // tryToFetchSinglePurchase
            .addCase(tryToFetchSinglePurchase.pending, (state) => {
                state.purchaseIsLoading = true;
            })
            .addCase(tryToFetchSinglePurchase.rejected, (state) => {
                state.purchaseIsLoading = false;
            })
            .addCase(tryToFetchSinglePurchase.fulfilled, (state, action) => {
                state.purchaseIsLoading = false;
                state.purchaseDetails = action.payload.data;
            })

            // tryToFetchPurchaseNote
            .addCase(tryToFetchPurchaseNote.pending, (state) => {
                state.purchaseNotesAreLoading = true;
            })
            .addCase(tryToFetchPurchaseNote.rejected, (state) => {
                state.purchaseNotesAreLoading = false;
            })
            .addCase(tryToFetchPurchaseNote.fulfilled, (state, action) => {
                state.purchaseNotesAreLoading = false;
                state.purchaseNotes = action.payload.data;
            })

            // tryToEditPurchase
            .addCase(tryToEditPurchase.pending, (state) => {
                state.purchaseIsLoading = true;
            })
            .addCase(tryToEditPurchase.rejected, (state) => {
                state.purchaseIsLoading = false;
            })
            .addCase(tryToEditPurchase.fulfilled, (state, action) => {
                state.purchaseIsLoading = false;
                state.purchaseDetails = action?.payload?.data;
            })

            // tryToAddPurchaseNote
            .addCase(tryToAddPurchaseNote.pending, (state) => {
                state.purchaseNotesAreLoading = true;
            })
            .addCase(tryToAddPurchaseNote.rejected, (state) => {
                state.purchaseNotesAreLoading = false;
            })
            .addCase(tryToAddPurchaseNote.fulfilled, (state, action) => {
                state.purchaseNotesAreLoading = false;
                state.purchaseNotes = action.payload;
            })

            // tryToCreatePurchase
            .addCase(tryToCreatePurchase.pending, (state) => {
                state.purchaseIsLoading = true;
            })
            .addCase(tryToCreatePurchase.rejected, (state) => {
                state.purchaseIsLoading = false;
            })
            .addCase(tryToCreatePurchase.fulfilled, (state, action) => {
                state.purchaseIsLoading = false;
                state.purchaseDetails = action.payload;
            })

            // tryToDeletePurchase
            .addCase(tryToDeletePurchase.pending, (state) => {
                state.purchaseIsLoading = true;
            })
            .addCase(tryToDeletePurchase.rejected, (state) => {
                state.purchaseIsLoading = false;
            })
            .addCase(tryToDeletePurchase.fulfilled, (state, action) => {
                state.purchaseIsLoading = false;
                state.requestStatus = 'success';
                if (state.purchases?.data.elements)
                    state.purchases.data.elements =
                        { ...state }.purchases?.data.elements.filter(
                            (purchase) => purchase?._id !== action.meta.arg,
                        ) || [];
            })

            // tryToFetchPurchaseContent
            .addCase(tryToFetchPurchaseContent.pending, (state) => {
                state.purchaseContentIsLoading = true;
            })
            .addCase(tryToFetchPurchaseContent.rejected, (state) => {
                state.purchaseContentIsLoading = false;
            })
            .addCase(tryToFetchPurchaseContent.fulfilled, (state, action) => {
                state.purchaseContentIsLoading = false;
                state.purchaseContent = action.payload;
            })

            // tryToAcceptPurchase
            .addCase(tryToAcceptPurchase.pending, (state) => {
                state.purchaseIsLoading = true;
            })
            .addCase(tryToAcceptPurchase.rejected, (state) => {
                state.purchaseIsLoading = false;
            })
            .addCase(tryToAcceptPurchase.fulfilled, (state, action) => {
                state.purchaseIsLoading = false;
                state.requestStatus = 'success';
                if (state.purchases?.data.elements)
                    state.purchases.data.elements =
                        { ...state }.purchases?.data.elements.filter(
                            (purchase) => purchase?._id !== action.meta.arg,
                        ) || [];
                if (state.purchaseDetails) {
                    state.purchaseDetails = {
                        ...state?.purchaseDetails || {},
                        status: 'accepted'
                    }
                }
            })

            // tryToPayPurchase
            .addCase(tryToPayPurchase.pending, (state) => {
                state.purchaseIsLoading = true;
            })
            .addCase(tryToPayPurchase.rejected, (state) => {
                state.purchaseIsLoading = false;
            })
            .addCase(tryToPayPurchase.fulfilled, (state, action) => {
                state.purchaseIsLoading = false;
                state.requestStatus = 'success';
                if (state.purchases?.data.elements)
                    state.purchases.data.elements =
                        { ...state }.purchases?.data.elements.filter(
                            (purchase) => purchase?._id !== action.meta.arg?.id,
                        ) || [];
                if (state.purchaseDetails) {
                    state.purchaseDetails = {
                        ...state?.purchaseDetails || {},
                        status: 'on_delivery'
                    }
                }
            })

            // tryToChangePurchaseStatus
            .addCase(tryToChangePurchaseStatus.pending, (state) => {
                state.purchaseIsLoading = true;
            })
            .addCase(tryToChangePurchaseStatus.rejected, (state) => {
                state.purchaseIsLoading = false;
            })
            .addCase(tryToChangePurchaseStatus.fulfilled, (state, action) => {
                state.purchaseIsLoading = false;
                state.requestStatus = 'success';
                if (state.purchases?.data.elements)
                    state.purchases.data.elements =
                        { ...state }.purchases?.data.elements.filter(
                            (purchase) => purchase?._id !== action.meta.arg?.id,
                        ) || [];
                if (state.purchaseDetails) {
                    state.purchaseDetails = {
                        ...state?.purchaseDetails || {},
                        status: action?.meta?.arg?.status
                    }
                }
            })

            // tryToSplitPurchase
            .addCase(tryToSplitPurchase.pending, (state) => {
                state.purchasesAreLoading = true;
            })
            .addCase(tryToSplitPurchase.rejected, (state) => {
                state.purchasesAreLoading = false;
            })
            .addCase(tryToSplitPurchase.fulfilled, (state, action) => {
                state.purchasesAreLoading = false;
                state.requestStatus = 'success';
                state.purchaseDetails = action?.payload
            })

            // tryToFetchMainOrder
            .addCase(tryToFetchMainOrder.pending, (state) => {
                state.mainOrderLoading = true;
            })
            .addCase(tryToFetchMainOrder.rejected, (state) => {
                state.mainOrderLoading = false;
            })
            .addCase(tryToFetchMainOrder.fulfilled, (state, action) => {
                state.mainOrderLoading = false;
                state.requestStatus = 'success';
                state.mainOrder = action?.payload?.data
            })

            // tryToFinalizePurchaseReview
            .addCase(tryToFinalizePurchaseReview.pending, (state) => {
                state.purchasesAreLoading = true;
            })
            .addCase(tryToFinalizePurchaseReview.rejected, (state) => {
                state.purchasesAreLoading = false;
            })
            .addCase(tryToFinalizePurchaseReview.fulfilled, (state, action) => {
                state.purchasesAreLoading = false;
                state.requestStatus = 'success';
                state.purchaseDetails = action?.payload
            })

            // tryToAddPurchasePackages
            .addCase(tryToAddPurchasePackages.pending, (state) => {
                state.purchaseIsLoading = true;
            })
            .addCase(tryToAddPurchasePackages.rejected, (state) => {
                state.purchaseIsLoading = false;
            })
            .addCase(tryToAddPurchasePackages.fulfilled, (state, action) => {
                state.purchaseIsLoading = false;
                state.requestStatus = 'success';
                if (state.purchases?.data.elements && action?.meta?.arg?.status === 'on_delivery')
                    state.purchases.data.elements =
                        { ...state }.purchases?.data.elements.filter((purchase) => purchase?._id !== action.meta.arg?.id) || [];
                if (state.purchaseDetails) {
                    state.purchaseDetails = {
                        ...state?.purchaseDetails || {},
                        status: 'delivered',
                    }
                }
            })

            // tryToAcceptPurchaseItem
            .addCase(tryToAcceptPurchaseItem.pending, (state) => {
                state.purchasesAreLoading = true;
            })
            .addCase(tryToAcceptPurchaseItem.rejected, (state) => {
                state.purchasesAreLoading = false;
            })
            .addCase(tryToAcceptPurchaseItem.fulfilled, (state, action) => {
                state.purchasesAreLoading = false;
                state.purchaseDetails = {
                    ...action.payload || {},
                    discrepancyItems: state.purchaseDetails?.discrepancyItems?.map((purchase) => {
                        if (purchase?.itemId === action.meta.arg?.itemId) {
                            return {
                                ...purchase || {},
                                discrepancyStatus: 'accepted'
                            }
                        } else {
                            return purchase
                        }
                    }) || []
                }
            })

            // tryToAwaitCreditPurchaseItem
            .addCase(tryToAwaitCreditPurchaseItem.pending, (state) => {
                state.purchasesAreLoading = true;
            })
            .addCase(tryToAwaitCreditPurchaseItem.rejected, (state) => {
                state.purchasesAreLoading = false;
            })
            .addCase(tryToAwaitCreditPurchaseItem.fulfilled, (state, action) => {
                state.purchasesAreLoading = false;
                let itemCredit = false
                state.purchaseDetails = {
                    ...action.payload || {},
                    discrepancyItems: state.purchaseDetails?.discrepancyItems?.map((purchase) => {
                        if (JSON.stringify(purchase) === JSON.stringify(action.meta.arg?.item) && !itemCredit) {
                            itemCredit = true
                            return {
                                ...purchase || {},
                                discrepancyStatus: 'awaiting_credit'
                            }
                        } else {
                            return purchase
                        }
                    }) || []
                }
            })

            // tryToRevaluePurchaseItem
            .addCase(tryToRevaluePurchaseItem.pending, (state) => {
                state.purchasesAreLoading = true;
            })
            .addCase(tryToRevaluePurchaseItem.rejected, (state) => {
                state.purchasesAreLoading = false;
            })
            .addCase(tryToRevaluePurchaseItem.fulfilled, (state, action) => {
                state.purchasesAreLoading = false;
                state.purchaseDetails = {
                    ...action.payload || {},
                    discrepancyItems: state.purchaseDetails?.discrepancyItems?.map((purchase) => {
                        if (purchase?.itemId === action.meta.arg?.itemId) {
                            return {
                                ...purchase || {},
                                discrepancyStatus: 'revalued'
                            }
                        } else {
                            return purchase
                        }
                    }) || []
                }
                if (state.purchaseContent) {
                    state.purchaseContent = { ...state }.purchaseContent?.filter(
                        (purchase) => purchase?.itemId !== action.meta.arg?.itemId,
                    ) || [];
                }
            })

            // tryToReturnPurchaseItem
            .addCase(tryToReturnPurchaseItem.pending, (state) => {
                state.purchasesAreLoading = true;
            })
            .addCase(tryToReturnPurchaseItem.rejected, (state) => {
                state.purchasesAreLoading = false;
            })
            .addCase(tryToReturnPurchaseItem.fulfilled, (state, action) => {
                state.purchasesAreLoading = false;
                state.purchaseDetails = {
                    ...action.payload || {},
                    discrepancyItems: state.purchaseDetails?.discrepancyItems?.map((purchase) => {
                        if (purchase?.itemId === action.meta.arg?.itemId) {
                            return {
                                ...purchase || {},
                                discrepancyStatus: 'return'
                            }
                        } else {
                            return purchase
                        }
                    }) || []
                }
            })

            // tryToMatchPurchaseItem
            .addCase(tryToMatchPurchaseItem.pending, (state) => {
                state.purchasesAreLoading = true;
            })
            .addCase(tryToMatchPurchaseItem.rejected, (state) => {
                state.purchasesAreLoading = false;
            })
            .addCase(tryToMatchPurchaseItem.fulfilled, (state, action) => {
                state.purchasesAreLoading = false;
                state.purchaseDetails = action.payload;
            })

            // tryToReturnAllPurchase
            .addCase(tryToReturnAllPurchase.pending, (state) => {
                state.purchasesAreLoading = true;
            })
            .addCase(tryToReturnAllPurchase.rejected, (state) => {
                state.purchasesAreLoading = false;
            })
            .addCase(tryToReturnAllPurchase.fulfilled, (state, action) => {
                state.purchasesAreLoading = false;
                state.purchaseDetails = action.payload;
            })
    },
});

export default purchasesSlice.reducer;
