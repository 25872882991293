import React, { useState } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import { tryToEditSaleStatus } from '../../../../store/inventory/shared/sales/salesSlice';
import Button from '../../../../shared/button';
import Error from '../../../../shared/error';
import Modal from '../../../../shared/modal';


interface IMarkProcessedModal {
    openModal: boolean;
    saleId: string;
    handleCloseModal: () => void;
    onSuccessfullyChanged: (type: string, message: string) => void;
}

const MarkProcessedModal = ({
    openModal,
    saleId,
    handleCloseModal,
    onSuccessfullyChanged,
}: IMarkProcessedModal) => {
    const dispatch = useAppDispatch();
    const [error, setError] = useState<string>();
    const [loading, setLoading] = useState<boolean>(false);

    const onCloseModal = () => {
        setError(undefined)
        setLoading(false)
        handleCloseModal()
    }

    const onApproveAction = async () => {
        setLoading(true)
        try {
            await dispatch(tryToEditSaleStatus({ id: saleId, status: 'processed' })).unwrap()
            onSuccessfullyChanged('success', 'Sale Order marked as processed successfully!')
            onCloseModal();
        } catch (err) {
            setError(`${err}`)
        }
        setLoading(false)
    }

    return (
        <Modal
            open={openModal}
            showInRight={true}
            title='Mark as Processed'
            onClose={() => onCloseModal()}>
            <div className={'p-2 min-w-[500px]'}>
                <div className='border-b-2 border-slate-200 pb-2 my-1'>
                    <p className='font-medium text-slate-700'>Action Verification</p>
                </div>
                <div className='bg-[#f8f9fc] rounded-[6px] p-3'>
                    <p className='mt-2 mb-6'>Do you want to mark this sales order as processed Sale?</p>
                    {error &&
                        <div className='flex flex-row justify-center my-5'>
                            <Error text={error} />
                        </div>
                    }
                    <div className='flex flex-row justify-around my-5'>
                        <Button
                            label='No'
                            dataQa={'no-button'}
                            className={`${loading ? 'btn-main-disable' : 'btn-main !bg-white !border-0'} min-w-[150px]`}
                            onClickButton={() => !loading && onCloseModal()}
                        />
                        <Button
                            label='Yes'
                            dataQa={'continue-button'}
                            className={`${loading ? 'btn-primary-disable !rounded' : 'btn-primary-rounded-less'} min-w-[150px]`}
                            onClickButton={() => !loading && onApproveAction()}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    )
}
export default MarkProcessedModal;