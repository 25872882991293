import React from 'react';

interface IButton {
  label: any;
  className?: string;
  disabled?: boolean;
  type?: any;
  icon?: React.ReactNode;
  scndIcon?: React.ReactNode;
  onClickButton?: (type?: string) => void;
  clickType?: string;
  dataQa?: string;
}

const Button = ({
  type,
  disabled,
  className,
  label,
  icon,
  scndIcon,
  clickType,
  dataQa,
  onClickButton
}: IButton) => {
  return (
    <button
      className={className || 'btn-primary'}
      type={type || 'button'}
      data-qa={dataQa || ''}
      onClick={(e) => onClickButton?.(clickType)}
      disabled={disabled || false}
    >
      {icon}
      {label}
      {scndIcon}
    </button>
  );
};

export default Button;
