import React, { useState } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import { CURRENCY_SYMBOLS } from '../../../../utils/constants/currencySymbols';
import { tryToSubmitPurchaseRequestOffer } from '../../../../store/inventory/broker/purchase-requests/purchaseRequestsSlice';
import Button from '../../../../shared/button';
import Modal from '../../../../shared/modal';
import Error from '../../../../shared/error';
import Input from '../../../../shared/input';


interface IOfferModal {
    openModal: boolean;
    data: any;
    price?: number;
    itemId: string;
    handleCloseModal: () => void;
    onSubmitOfferSuccessfully: () => void;
}

const OfferModal = ({
    openModal,
    data,
    price,
    itemId,
    handleCloseModal,
    onSubmitOfferSuccessfully,
}: IOfferModal) => {
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState<boolean>(false);
    const [submitError, setSubmitError] = useState<string>();
    const [priceOffer, setPriceOffer] = useState<any>();

    const onCloseModal = () => {
        setLoading(false)
        setSubmitError(undefined)
        setPriceOffer(undefined)
        handleCloseModal()
    }

    const onMakeNewOffer = async () => {
        setLoading(true)
        if (!priceOffer) {
            setSubmitError('Please write your offer to continue!')
            setLoading(false)
            return;
        }
        try {
            await dispatch(tryToSubmitPurchaseRequestOffer({ id: itemId, status: 'offer_submitted', data: { totalOfferPrice: Number(priceOffer || 0) } })).unwrap();
            onSubmitOfferSuccessfully()
            onCloseModal()
        } catch (err) {
            setSubmitError(`${err}`)
        }
        setLoading(false)
    }

    const onChangePriceOffer = (value: any, type?: string) => {
        setPriceOffer(/^\d*\.?\d*$/.test(value) ? value : priceOffer)
    }

    return (
        <Modal
            open={openModal}
            showInRight
            title={'Purchase Request Offer'}
            onClose={onCloseModal}
        >
            <div className={'p-2 min-w-[500px]'}>
                <div className='border-b-2 border-slate-200 pb-2 my-1'>
                    <p className='font-medium text-slate-700'>Request Offer</p>
                </div>
                <div className='p-2 !rounded bg-[#f8f9fc]'>
                    <p className='mt-2 mb-5 text-[17px] font-medium'>This will be the offer covering the entire order.</p>
                    {submitError &&
                        <div className='my-3'>
                            <Error text={submitError} />
                        </div>
                    }
                    {(data?.offerPrice || price) &&
                        <div>
                            < p className='mt-6 text-[13px]'>Broker offer</p>
                            <p className='mb-9 mt-3 font-bold ml-4 text-[17px]'>{CURRENCY_SYMBOLS?.[data?.currency || '']}{Number(data?.offerPrice || price || 0)?.toFixed(2)}</p>
                        </div>
                    }
                    <Input
                        label='Offer Price'
                        showValue={true}
                        containerStyle='my-4'
                        inputValue={priceOffer}
                        onChangeInput={onChangePriceOffer}
                    />
                    <div className='flex flex-row justify-around items-center mt-7 mb-3'>
                        <Button
                            label='Submit Offer'
                            dataQa={'accept-button'}
                            className={`${loading && priceOffer ? 'btn-primary-disable !rounded' : 'btn-primary-rounded-less'} !shadow-none min-w-[150px]`}
                            onClickButton={() => !loading && priceOffer && onMakeNewOffer()}
                        />
                    </div>
                </div>
            </div>
        </Modal >
    )
}
export default OfferModal;