import React from 'react'
import moment from 'moment';
import { IInventory } from '../../../../interfaces/inventory-data/IInventory';
import WhiteContainer from '../../../../shared/white-container';
import Button from '../../../../shared/button';


interface IItemOrderDetailsContainer {
    inventoryDetails?: IInventory;
    differenceDate?: any;
    accessControl?: any;
    actions?: any;
    onTogglePrintLabel: () => void;
}

const ItemOrderDetailsContainer = ({
    inventoryDetails,
    differenceDate,
    accessControl,
    actions,
    onTogglePrintLabel
}: IItemOrderDetailsContainer) => {
    return (
        <WhiteContainer containerStyle=' !h-auto !rounded p-4 mt-2'>
            <p className='font-bold text-[18px] mb-4'>Purchase/Order Information</p>
            <div>
                <div className='flex flex-row items-center my-2'>
                    <p className='mr-2 text-primary-light'>Created:</p>
                    <p className='text-primary-light'>
                        {moment(inventoryDetails?.createdAt).format('DD/MM/YYYY, HH:mm')}
                    </p>
                </div>
                <div className='flex flex-row items-center my-3'>
                    <p className='mr-2 text-primary-light'>Booked In:</p>
                    <p className='text-primary-light'>
                        {moment(inventoryDetails?.bookedIn || inventoryDetails?.createdAt).format('DD/MM/YYYY, HH:mm')}
                    </p>
                </div>
                <div className='flex flex-row items-center my-3'>
                    <p className='mr-2 text-primary-light'>Time in System:</p>
                    <p className='text-primary-light'>{differenceDate}</p>
                </div>
                <div className='flex flex-row items-center my-3'>
                    <p className='mr-2 text-primary-light'>Order:</p>
                    <p className='text-primary-light'>{inventoryDetails?.orderId || 'N/A'}</p>
                </div>
                {inventoryDetails?.locations && Object.keys(inventoryDetails?.locations)?.length > 0 &&
                    <div className='flex flex-row items-center my-3'>
                        <p className='mr-2 text-primary-light'>Location:</p>
                        <p className='text-primary-light'>
                            {(Object.keys(inventoryDetails?.locations))?.map((loc: any, index: number) => {
                                return `${index === 0 ? `${inventoryDetails?.locations?.[loc]}` : ` - ${inventoryDetails?.locations?.[loc]}`}`
                            })}
                        </p>
                    </div>
                }
                <div className='my-3'>
                    <p className='mr-2 text-primary-light'>Print Label: (please note, any changes to item must be saved before printing label)</p>
                    <Button
                        icon={<img src={'/assets/shared/printer.svg'} className={'w-[20px] object-cover mr-2'} />}
                        label={'Print Label'}
                        dataQa={'print-label'}
                        onClickButton={() => (!accessControl || actions?.['Print Label']) && onTogglePrintLabel()}
                        className={'btn-primary-rounded-less flex flex-row align-start my-2 !py-2'}
                    />
                </div>
            </div>
        </WhiteContainer>
    )
}

export default ItemOrderDetailsContainer