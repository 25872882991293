import React from 'react';
import Table from '../../../../shared/table';
import Input from '../../../../shared/input';
import AsyncSelectCheckbox from '../../../../shared/select-checkbox-async';
import Button from '../../../../shared/button';


interface IItemsLocationTable {
    listValues?: any;
    itemsError?: any;
    itemsCurrentLocations?: any;
    responseLocations?: any;
    filteredLocations?: any;
    loadingOptions?: boolean;
    searchInDB?: any;
    itemIdsError?: any;
    onMenuCloseAfterSearch: () => void;
    onChangeSearchLocationField: (e: any, type?: string) => void;
    onClickSearchInDb: () => void;
    onClickClearRow: (index: number) => void;
    onRemoveListValue: (index: number) => void;
    onChangeItemId: (e: any, type?: string, index?: any) => void;
    onEnterItemIdInput: (e: any, type?: string, index?: any) => void;
    onChangeSelectedLocation: (e: any, type?: string, index?: any) => void;
}

const ItemsLocationTable = ({
    listValues,
    itemsError,
    itemIdsError,
    itemsCurrentLocations,
    responseLocations,
    filteredLocations,
    loadingOptions,
    searchInDB,
    onClickClearRow,
    onMenuCloseAfterSearch,
    onChangeSearchLocationField,
    onClickSearchInDb,
    onChangeItemId,
    onRemoveListValue,
    onEnterItemIdInput,
    onChangeSelectedLocation
}: IItemsLocationTable) => {
    const countItemsForEachCurrentLocation = (itemsCurrentLocations && itemsCurrentLocations?.length > 0) && itemsCurrentLocations?.reduce((acc: any, obj: any) => {
        const key = obj?.location;
        const findIfReplaceSomewhere = listValues && listValues?.length > 0 && listValues?.find((item: any) => ((item?.itemId === obj?.itemId) && item?.location?.name))
        if (findIfReplaceSomewhere) {
            if (!acc[key]) {
                acc[key] = { location: key, count: 1 };
            } else {
                acc[key].count++;
            }
        } else {
            acc[key] = { location: key, count: 0 }
        }
        return acc;
    }, {});

    const resultArray = countItemsForEachCurrentLocation && Object.values(countItemsForEachCurrentLocation);
    return (
        <Table
            hidePagination={true}
            containerStyle='min-h-[200px]'
            columns={['Item ID / IMEI', 'Currrent Location', 'New Location', 'Action']}>
            {listValues?.map((obj: any, idx: number) => {
                const findCurrentLocation = itemsCurrentLocations && itemsCurrentLocations?.length > 0 && itemsCurrentLocations?.find((item: any) => item?.itemId === obj?.itemId)
                const refilterLocations = (filteredLocations && filteredLocations?.length > 0 ? filteredLocations : responseLocations || [])?.map((item: any) => {
                    const findIfFreeFromCurrent = (resultArray && resultArray?.length > 0) ? resultArray?.find((result: any) => result?.location === item?.name)?.count || 0 : 0
                    const findUsedLocation = (listValues && listValues?.length > 0) ? listValues?.filter((obj: any, index: number) => obj && (obj?.location?.name === item?.name) && (idx !== index)) || [] : []
                    const availableCapacity = item?.capacity ? (Number(item?.capacity || 0) - ((Number(item?.filledCapacity || 0) - findIfFreeFromCurrent) || 0) - (findUsedLocation?.length || 0)) : 1
                    return {
                        ...item || {},
                        availableCapacity: availableCapacity
                    }
                })
                const formatedLocations: any = (refilterLocations && refilterLocations?.length > 0) ? refilterLocations?.filter((item: any) => item?.availableCapacity > 0) || [] : []
                if (obj?.location) {
                    formatedLocations.push(obj?.location)
                }
                return (
                    <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                        <td className='px-6 py-2'>
                            <Input
                                placeholder='Item ID / IMEI'
                                showValue={true}
                                inputValue={obj?.itemId}
                                inputUniqueName='itemId'
                                index={idx}
                                error={itemsError?.itemIds?.[idx] || (itemIdsError?.[obj?.itemId] && itemIdsError?.[obj?.itemId] !== true ? itemIdsError?.[obj?.itemId] : false) || undefined}
                                onChangeInput={onChangeItemId}
                                onHandleKeyDown={onEnterItemIdInput}
                            />
                        </td>
                        <td className='px-6 py-2 flex flex-row items-center'>
                            {findCurrentLocation?.location || ''}
                        </td>
                        <td className='px-6 py-2 cursor-pointer'>
                            <AsyncSelectCheckbox
                                isLoading={false}
                                options={formatedLocations || []}
                                placeholder={obj?.location?.name || ''}
                                dynamicOptions={true}
                                loadingOptions={loadingOptions}
                                selectedOption={obj?.location}
                                uniqueName='location'
                                index={idx}
                                showSearchDB={searchInDB || false}
                                error={itemsError?.locations?.[idx]}
                                onChangeValue={onChangeSearchLocationField}
                                onMenuClose={onMenuCloseAfterSearch}
                                onClickSearchInDb={onClickSearchInDb}
                                onChangeSelectedOption={onChangeSelectedLocation}
                            />
                        </td>
                        <td className='px-6 py-2 cursor-pointer flex flex-row items-center'>
                            <Button
                                label='Clear'
                                className='btn-primary-text-underline mr-3 mt-2'
                                onClickButton={() => onClickClearRow(idx)}
                            />
                            <div onClick={() => onRemoveListValue(idx)}>
                                <img src={'/assets/shared/trash-gray.svg'} className={'w-[15px] mt-2 ml-2 hover:w-[17px] object-contain'} />
                            </div>
                        </td>
                    </tr>
                );
            })}
        </Table>
    )
}

export default ItemsLocationTable;