import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { ISalesList } from '../../../../interfaces/sales/ISalesList';
import { SalesService } from './salesApi';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';


const initialState: ISalesList = {
    saleFieldsAreLoading: false,
    saleNotesAreLoading: false,
    saleContentIsLoading: false,
    saleIsLoading: false,
    salesAreLoading: false,
    saleDetailsIsLoading: false,
    saleReviewIsLoading: false,
    requestStatus: 'default',
    message: '',
};

export const tryToFetchSales = createAsyncThunk<any, IPaginationPayload>(
    'sales/tryToFetchSales',
    async ({ pageNumber, pageSize, data }) => {
        const result = await SalesService.tryToFetchSales(
            pageNumber,
            pageSize,
            data,
        );
        return result;
    });

export const tryToFetchSingleSaleForm = createAsyncThunk<any, string>(
    'sales/tryToFetchSingleSaleForm',
    async (id: string) => {
        const result = await SalesService.tryToFetchSingleSaleForm(id);
        return result?.data;
    },
);

export const tryToFetchSingleSale = createAsyncThunk<any, string>(
    'sales/tryToFetchSingleSale',
    async (id: string) => {
        const result = await SalesService.tryToFetchSingleSale(id);
        return result?.data?.data;
    },
);

export const tryToFetchSaleOrderSplitDetails = createAsyncThunk<any, string>(
    'sales/tryToFetchSaleOrderSplitDetails',
    async (id: string) => {
        const result = await SalesService.tryToFetchSaleOrderSplitDetails(id);
        return result?.data;
    },
);

export const tryToFetchSaleOrderContent = createAsyncThunk<any, string>(
    'sales/tryToFetchSaleOrderContent',
    async (id: string) => {
        const result = await SalesService.tryToFetchSaleOrderContent(id);
        return result?.data?.data;
    },
);

export const tryToSplitOrderSale = createAsyncThunk<any, any>(
    'sales/tryToSplitOrderSale',
    async (id) => {
        const result = await SalesService.tryToSplitOrderSale(id);
        return result?.data;
    },
);

export const tryToConfirmReviewSale = createAsyncThunk<any, any>(
    'sales/tryToConfirmReviewSale',
    async ({ id, data }, { rejectWithValue }) => {
        try {
            const result = await SalesService.tryToConfirmReviewSale(id, data);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToEditSaleStatus = createAsyncThunk<any, any>(
    'sales/tryToEditSaleStatus',
    async ({ id, status, data }, { rejectWithValue }) => {
        try {
            const result = await SalesService.tryToEditSaleStatus(id, status, data);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToEditSaleFields = createAsyncThunk<any, any>(
    'sales/tryToEditSaleFields',
    async (data: any, { rejectWithValue }) => {
        try {
            const result = await SalesService.tryToEditSaleFields(data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const tryToEditSaleOrderStatuses = createAsyncThunk<any, any>(
    'sales/tryToEditSaleOrderStatuses',
    async ({ id, data }, { rejectWithValue }) => {
        try {
            const result = await SalesService.tryToEditSaleOrderStatuses(id, data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const tryToEditSaleForm = createAsyncThunk<any, any>(
    'sales/tryToEditSaleForm',
    async ({ id, data }, { rejectWithValue }) => {
        try {
            const result = await SalesService.tryToEditSaleForm(id, data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const tryToCreateSaleOrder = createAsyncThunk<any, any>(
    'sales/tryToCreateSaleOrder',
    async (data: any, { rejectWithValue }) => {
        try {
            const result = await SalesService.tryToCreateSaleOrder(data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const tryToFetchSaleReview = createAsyncThunk<any, string>(
    'sales/tryToFetchSaleReview',
    async (id: string) => {
        const result = await SalesService.tryToFetchSaleReview(id);
        return result?.data;
    },
);

export const tryToEditSaleReview = createAsyncThunk<any, any>(
    'sales/tryToEditSaleReview',
    async ({ id, data }, { rejectWithValue }) => {
        try {
            const result = await SalesService.tryToEditSaleReview(id, data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const tryToRefreshStock = createAsyncThunk<any, string>(
    'sales/tryToRefreshStock',
    async (id, { rejectWithValue }) => {
        try {
            const result = await SalesService.tryToRefreshStock(id);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const tryToReturnToStockedPickedItem = createAsyncThunk<any, any>(
    'sales/tryToReturnToStockedPickedItem',
    async ({ id, data }, { rejectWithValue }) => {
        try {
            const result = await SalesService.tryToReturnToStockedPickedItem(id, data);
            return result.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    }
);

export const tryToAddSalesOrderNote = createAsyncThunk<any, any>(
    'sales/tryToAddSalesOrderNote',
    async ({ id, data }) => {
        const result = await SalesService.tryToAddSalesOrderNote(id, data);
        return result?.data;
    },
);

export const tryToRequestSaleItems = createAsyncThunk<any, any>(
    'sales/tryToRequestSaleItems',
    async ({ id, data }, { rejectWithValue }) => {
        try {
            const result = await SalesService.tryToRequestSaleItems(id, data);
            return result?.data?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToFetchSalesOrderNote = createAsyncThunk<any, string>(
    'sales/tryToFetchSalesOrderNote',
    async (id: string) => {
        const result = await SalesService.tryToFetchSalesOrderNote(id);
        return result?.data;
    },
);

export const tryToCheckSaleOrderAffected = createAsyncThunk<any, any>(
    'sales/tryToCheckSaleOrderAffected',
    async ({ id, data }, { rejectWithValue }) => {
        try {
            const result = await SalesService.tryToCheckSaleOrderAffected(id, data);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToAcceptRejectSaleItem = createAsyncThunk<any, any>(
    'sales/tryToAcceptRejectSaleItem',
    async ({ id, item }, { rejectWithValue }) => {
        try {
            const result = await SalesService.tryToAcceptRejectSaleItem(id, item);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToShowSaleSplit = createAsyncThunk<any, boolean | undefined>(
    'sales/tryToShowSaleSplit',
    async (data) => {
        return data;
    }
);

export const tryToGenerateInvoice = createAsyncThunk<any, any>(
    'sales/tryToGenerateInvoice',
    async ({ id, data }, { rejectWithValue }) => {
        try {
            const result = await SalesService.tryToGenerateInvoice(id, data);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToGenerateProForma = createAsyncThunk<any, any>(
    'sales/tryToGenerateProForma',
    async ({ id, data }, { rejectWithValue }) => {
        try {
            const result = await SalesService.tryToGenerateProForma(id, data);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const tryToShipSaleOrder = createAsyncThunk<any, any>(
    'sales/tryToShipSaleOrder',
    async ({ id, data }, { rejectWithValue }) => {
        try {
            const result = await SalesService.tryToShipSaleOrder(id, data);
            return result?.data;
        } catch (error) {
            const err: any = error as AxiosError;
            return rejectWithValue(err?.response?.data?.message);
        }
    },
);

export const salesSlice = createSlice({
    name: 'sales',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // tryToFetchSales
            .addCase(tryToFetchSales.pending, (state) => {
                state.salesAreLoading = true;
            })
            .addCase(tryToFetchSales.rejected, (state) => {
                state.salesAreLoading = false;
            })
            .addCase(tryToFetchSales.fulfilled, (state, action) => {
                state.salesAreLoading = false;
                state.sales = action.payload;
            })

            // tryToFetchSingleSaleForm
            .addCase(tryToFetchSingleSaleForm.pending, (state) => {
                state.saleIsLoading = true;
            })
            .addCase(tryToFetchSingleSaleForm.rejected, (state) => {
                state.saleIsLoading = false;
            })
            .addCase(tryToFetchSingleSaleForm.fulfilled, (state, action) => {
                state.saleIsLoading = false;
                state.sale = action.payload.data;
            })


            // tryToFetchSaleOrderContent
            .addCase(tryToFetchSaleOrderContent.pending, (state) => {
                state.saleContentIsLoading = true;
            })
            .addCase(tryToFetchSaleOrderContent.rejected, (state) => {
                state.saleContentIsLoading = false;
            })
            .addCase(tryToFetchSaleOrderContent.fulfilled, (state, action) => {
                state.saleContentIsLoading = false;
                state.saleContent = action.payload;
            })

            // tryToFetchSaleOrderSplitDetails
            .addCase(tryToFetchSaleOrderSplitDetails.pending, (state) => {
                state.saleIsLoading = true;
            })
            .addCase(tryToFetchSaleOrderSplitDetails.rejected, (state) => {
                state.saleIsLoading = false;
            })
            .addCase(tryToFetchSaleOrderSplitDetails.fulfilled, (state, action) => {
                state.saleIsLoading = false;
                state.saleDetails = action.payload.data;
            })

            // tryToSplitOrderSale
            .addCase(tryToSplitOrderSale.pending, (state) => {
                state.saleDetailsIsLoading = true;
            })
            .addCase(tryToSplitOrderSale.rejected, (state) => {
                state.saleDetailsIsLoading = false;
            })
            .addCase(tryToSplitOrderSale.fulfilled, (state, action) => {
                state.saleDetailsIsLoading = false;
                state.saleDetails = action.payload?.data?.parentOrder;
            })

            // tryToEditSaleForm
            .addCase(tryToEditSaleForm.pending, (state) => {
                state.saleIsLoading = true;
            })
            .addCase(tryToEditSaleForm.rejected, (state) => {
                state.saleIsLoading = false;
            })
            .addCase(tryToEditSaleForm.fulfilled, (state, action) => {
                state.saleIsLoading = false;
                state.sale = action?.payload?.data;
            })

            // tryToEditSaleFields
            .addCase(tryToEditSaleFields.pending, (state) => {
                state.salesAreLoading = true;
            })
            .addCase(tryToEditSaleFields.rejected, (state) => {
                state.salesAreLoading = false;
            })
            .addCase(tryToEditSaleFields.fulfilled, (state, action) => {
                state.salesAreLoading = false;
                state.saleFields = action.payload;
            })

            // tryToEditSaleStatus
            .addCase(tryToEditSaleStatus.pending, (state) => {
                state.saleIsLoading = true;
            })
            .addCase(tryToEditSaleStatus.rejected, (state) => {
                state.saleIsLoading = false;
            })
            .addCase(tryToEditSaleStatus.fulfilled, (state, action) => {
                state.saleIsLoading = false;
                // state.sales = action.payload;
                if (state.sales?.data?.elements)
                    state.sales.data.elements =
                        { ...state }.sales?.data?.elements.filter((sale) => (sale?._id !== action.meta?.arg?.id)) || [];
                if (state.sale) {
                    state.sale = {
                        ...state.sale || {},
                        status: action?.meta?.arg?.status
                    }
                }
            })


            // tryToShipSaleOrder
            .addCase(tryToShipSaleOrder.pending, (state) => {
                state.saleIsLoading = true;
            })
            .addCase(tryToShipSaleOrder.rejected, (state) => {
                state.saleIsLoading = false;
            })
            .addCase(tryToShipSaleOrder.fulfilled, (state, action) => {
                state.saleIsLoading = false;
                // state.sales = action.payload;
                if (state.sales?.data?.elements)
                    state.sales.data.elements =
                        { ...state }.sales?.data?.elements.filter((sale) => (sale?._id !== action.meta?.arg?.id)) || [];
                if (state.sale) {
                    state.sale = {
                        ...state.sale || {},
                        shippingInfo: action?.meta?.arg?.data,
                        status: 'shipped'
                    }
                }
            })

            // tryToFetchSingleSale
            .addCase(tryToFetchSingleSale.pending, (state) => {
                state.saleFieldsAreLoading = true;
            })
            .addCase(tryToFetchSingleSale.rejected, (state) => {
                state.saleFieldsAreLoading = false;
            })
            .addCase(tryToFetchSingleSale.fulfilled, (state, action) => {
                state.saleFieldsAreLoading = false;
                state.saleFields = action.payload || undefined;
            })

            // tryToFetchSaleReview
            .addCase(tryToFetchSaleReview.pending, (state) => {
                state.saleReviewIsLoading = true;
            })
            .addCase(tryToFetchSaleReview.rejected, (state) => {
                state.saleReviewIsLoading = false;
            })
            .addCase(tryToFetchSaleReview.fulfilled, (state, action) => {
                state.saleReviewIsLoading = false;
                state.saleReview = action.payload;
            })

            // tryToFetchSalesOrderNote
            .addCase(tryToFetchSalesOrderNote.pending, (state) => {
                state.saleNotesAreLoading = true;
            })
            .addCase(tryToFetchSalesOrderNote.rejected, (state) => {
                state.saleNotesAreLoading = false;
            })
            .addCase(tryToFetchSalesOrderNote.fulfilled, (state, action) => {
                state.saleNotesAreLoading = false;
                state.saleNotes = action.payload.data;
            })

            // tryToAddSalesOrderNote
            .addCase(tryToAddSalesOrderNote.pending, (state) => {
                state.saleNotesAreLoading = true;
            })
            .addCase(tryToAddSalesOrderNote.rejected, (state) => {
                state.saleNotesAreLoading = false;
            })
            .addCase(tryToAddSalesOrderNote.fulfilled, (state, action) => {
                state.saleNotesAreLoading = false;
                state.saleNotes = action.payload?.notes;
            })

            // tryToShowSaleSplit
            .addCase(tryToShowSaleSplit.fulfilled, (state, action) => {
                state.showSplitSale = action.payload;
            })

            // tryToGenerateInvoice
            .addCase(tryToGenerateInvoice.pending, (state) => {
                state.saleNotesAreLoading = true;
            })
            .addCase(tryToGenerateInvoice.rejected, (state) => {
                state.saleNotesAreLoading = false;
            })
            .addCase(tryToGenerateInvoice.fulfilled, (state, action) => {
                state.saleNotesAreLoading = false;
                // state.sales = action.payload;
                if (state.sales?.data?.elements)
                    state.sales.data.elements =
                        { ...state }.sales?.data?.elements.map((sale: any) => {
                            if (sale?._id === action.meta?.arg?.id) {
                                return {
                                    ...sale || {},
                                    invoiceData: {
                                        pdfLink: action?.payload?.data?.pdfLink
                                    }
                                }
                            } else {
                                return sale
                            }

                        }) || [];
                if (state.sale) {
                    state.sale = {
                        ...state.sale || {},
                        invoiceData: {
                            pdfLink: action?.payload?.data?.pdfLink
                        }
                    }
                }
            })

            // tryToGenerateProForma
            .addCase(tryToGenerateProForma.pending, (state) => {
                state.saleNotesAreLoading = true;
            })
            .addCase(tryToGenerateProForma.rejected, (state) => {
                state.saleNotesAreLoading = false;
            })
            .addCase(tryToGenerateProForma.fulfilled, (state, action) => {
                state.saleNotesAreLoading = false;
                // state.sales = action.payload;
                if (state.sales?.data?.elements)
                    state.sales.data.elements =
                        { ...state }.sales?.data?.elements.map((sale: any) => {
                            if (sale?._id === action.meta?.arg?.id) {
                                return {
                                    ...sale || {},
                                    proFormData: {
                                        pdfLink: action?.payload?.data?.pdfLink
                                    }
                                }
                            } else {
                                return sale
                            }
                        }) || [];
                if (state.sale) {
                    state.sale = {
                        ...state.sale || {},
                        proFormData: {
                            pdfLink: action?.payload?.data?.pdfLink
                        }
                    }
                }
            })
    },
});

export default salesSlice.reducer;
