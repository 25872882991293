import React from 'react';
import {
    IAddress,
    IAddressErrors
} from '../../interfaces/auth/ICreateUserSchema';
import SelectCheckbox from '../select-checkbox';
import Button from '../button';
import Input from '../input';
import Modal from '../modal';
import { VAT_TYPE_ITEMS } from '../../utils/constants/vat-types';


interface INewAddressModal {
    isWarehouse?: boolean;
    selectedMarket?: string;
    markets?: Array<any>;
    newAddress: IAddress;
    openAddressModal: boolean;
    addressId?: number | null | undefined;
    errors?: IAddressErrors | null | undefined;
    type: string;
    searchAddress?: string;
    loading?: boolean;
    results?: any;
    warehouseName?: string;
    selectedVat?: any;
    showResults?: boolean;
    hideResults?: () => void;
    onShowResult?: () => void;
    onSelectOneAddress?: (address: any) => void;
    onPressEnterSearch?: (key: any) => void;
    handleCloseAddressModal: () => void;
    onChangeSearchAddress?: (e: any, type?: string) => void;
    onChangeSelectedMarket?: (e: any, type?: string) => void;
    onChangeVatType?: (e: any, type?: string) => void;
    onSaveChanges: (type: string, index?: number) => void;
    onChangeAddressInput: (value: string | null | undefined, type: string) => void;
    onChangeWarehouseName?: (value: string | null | undefined, type: string) => void;
}

const AddressModal = ({
    openAddressModal,
    newAddress,
    addressId,
    searchAddress,
    errors,
    warehouseName,
    loading,
    type,
    isWarehouse,
    selectedMarket,
    markets,
    results,
    showResults,
    selectedVat,
    hideResults,
    onShowResult,
    onSelectOneAddress,
    onPressEnterSearch,
    onChangeSearchAddress,
    onChangeSelectedMarket,
    onChangeWarehouseName,
    onChangeVatType,
    onSaveChanges,
    onChangeAddressInput,
    handleCloseAddressModal,
}: INewAddressModal) => {
    return (
        <Modal
            open={openAddressModal}
            onClose={handleCloseAddressModal}
        >
            <div className={'p-2 min-w-[500px]'} onClick={() => hideResults?.()}>
                <p className='mt-2 mb-4'>{isWarehouse ? 'New Warehouse' : (addressId || addressId === 0) ? 'Edit Address' : 'New Address'}</p>
                {isWarehouse &&
                    <div className='relative'
                        onClick={(e) => {
                            e.stopPropagation()
                            e.preventDefault()
                            onShowResult?.()
                        }}>
                        <Input
                            label='Search for an address'
                            placeholder='Press Enter to search address'
                            showValue={true}
                            inputValue={searchAddress}
                            onHandleKeyDown={onPressEnterSearch}
                            onChangeInput={onChangeSearchAddress}
                        />
                        {(showResults && results && results?.length > 0) && (
                            <div className='absolute shadow-md top-16 mt-2 left-0 w-full border bg-white rounded p-2 z-10'>
                                {results.map((result: any, index: number) => (
                                    <div
                                        key={index}
                                        onClick={() => onSelectOneAddress?.(result)}
                                        className='cursor-pointer hover:bg-[#f3f3f3] px-1 py-2 rounded max-h-[120px] overflow-y-auto'>
                                        <p>
                                            {result?.formatted_address}
                                        </p>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                }
                {isWarehouse && <>
                    <SelectCheckbox
                        name='Market'
                        dataQa={'select-market'}
                        containerStyle='mb-1 min-w-full'
                        selectedOption={selectedMarket}
                        multiple={true}
                        error={errors?.market}
                        onChangeSelectedOption={(e) => onChangeSelectedMarket?.(e, 'market')}
                        options={markets || []}
                    />
                    <SelectCheckbox
                        name='VAT Type'
                        dataQa={'select-vat'}
                        containerStyle='mb-1 min-w-full'
                        selectedOption={selectedVat}
                        multiple={true}
                        onChangeSelectedOption={(e) => onChangeVatType?.(e, 'vat')}
                        options={VAT_TYPE_ITEMS || []}
                    />
                    <Input
                        containerStyle='mb-0'
                        label='Warehouse Name'
                        dataQa={'warehouse-nome'}
                        showValue={true}
                        inputValue={warehouseName}
                        error={errors?.warehouseName}
                        onChangeInput={onChangeWarehouseName}
                        inputUniqueName={'warehouseName'}
                    />
                </>
                }
                <div className='grid grid-cols-2 gap-4 content-center pb-6'>
                    <Input
                        containerStyle='mb-0'
                        label='Street'
                        dataQa={'street'}
                        showValue={true}
                        inputValue={newAddress?.street}
                        error={errors?.street}
                        onChangeInput={onChangeAddressInput}
                        inputUniqueName={'street'}
                    />
                    <Input
                        containerStyle='mb-0'
                        label='Address No'
                        dataQa={'address-no'}
                        showValue={true}
                        inputValue={newAddress?.addressNo}
                        error={errors?.addressNo}
                        onChangeInput={onChangeAddressInput}
                        inputUniqueName={'addressNo'}
                    />
                    <Input
                        containerStyle='mb-0'
                        label='City'
                        dataQa={'city'}
                        showValue={true}
                        inputValue={newAddress?.city}
                        error={errors?.city}
                        onChangeInput={onChangeAddressInput}
                        inputUniqueName={'city'}
                    />
                    <Input
                        containerStyle='mb-0'
                        label='Postal Code'
                        dataQa={'postal-code'}
                        showValue={true}
                        inputValue={newAddress?.postalCode}
                        error={errors?.postalCode}
                        onChangeInput={onChangeAddressInput}
                        inputUniqueName={'postalCode'}
                    />
                    <Input
                        containerStyle='mb-0'
                        label='State'
                        dataQa={'state'}
                        showValue={true}
                        inputValue={newAddress?.state}
                        error={errors?.state}
                        onChangeInput={onChangeAddressInput}
                        inputUniqueName={'state'}
                    />
                    <Input
                        label='Country'
                        dataQa={'country'}
                        containerStyle='mb-0'
                        inputValue={newAddress?.country}
                        showValue={true}
                        onChangeInput={onChangeAddressInput}
                        inputUniqueName={'country'}
                        error={errors?.country}
                    />
                    {isWarehouse &&
                        <>
                            <Input
                                containerStyle='mb-0'
                                label='Email'
                                dataQa={'email'}
                                showValue={true}
                                inputValue={newAddress?.email}
                                error={errors?.email}
                                onChangeInput={onChangeAddressInput}
                                inputUniqueName={'email'}
                            />
                            <Input
                                containerStyle='mb-0'
                                label='Phone'
                                dataQa={'phone'}
                                showValue={true}
                                inputValue={newAddress?.phone}
                                error={errors?.phone}
                                onChangeInput={onChangeAddressInput}
                                inputUniqueName={'phone'}
                            />
                        </>
                    }
                </div>
                <div className='flex flex-row justify-end my-2'>
                    <Button
                        label={isWarehouse ? 'Save Warehouse' : 'Save Address'}
                        dataQa={'save-warehouse/address'}
                        className={`${loading ? 'btn-primary-disable' : 'btn-primary'}`}
                        onClickButton={() => !loading && (addressId || addressId === 0) ? onSaveChanges(type, addressId) : onSaveChanges(type)}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default AddressModal;