import React, { useState, useEffect } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { useAppDispatch, useAppSelector } from './app/hooks';
import { AdminGuard } from './guards/RolesGuard';
import { UserGuard } from './guards/UserGuard';
import { routes } from './router/routes';
import RouteWrapper from './router/RouteWrapper';
import { tryToFetchNavigationAccess } from './store/inventory/shared/partners/partnersSlice';


function App() {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const redirectNotExistingRoutes = UserGuard() ? (AdminGuard() ? '/sales-admin' : '/inventory') : '/login'
    const [redirectPartner, setRedirectPartner] = useState<any>()
    const token = localStorage.getItem('accessToken')
    const navigation = useAppSelector(state => state?.partners?.navigationAccess)

    const getNavigationData = async () => {
        try {
            await dispatch(tryToFetchNavigationAccess()).unwrap()
        } catch (err) {
            // error data here
        }
    }

    useEffect(() => {
        if (!navigation && token) {
            getNavigationData()
        }
    }, [])

    useEffect(() => {
        if (!token) {
            localStorage.removeItem('refreshToken')
            navigate('/login')
        }
    }, [token])

    const findChildIfExist = (parent: any, childPath: any) => {
        const findParent = (navigation && navigation?.length > 0) && navigation?.find((item: any) => item?.path === parent)
        const findChild = findParent && findParent?.children && findParent?.children?.length > 0 && findParent?.children?.find((item: any) => item?.path === childPath)
        return !navigation || !!findChild
    }

    const findParentIfExist = (parent: any) => {
        const findParent = (navigation && navigation?.length > 0) && navigation?.find((item: any) => item?.path === parent)
        return !navigation || !!findParent
    }

    useEffect(() => {
        if (navigation && token && (!redirectPartner || redirectPartner === '/login')) {
            const findIfInsightsExists = (navigation && navigation?.length > 0) && navigation?.find((item: any) => item?.path === '/inventory')
            if (!findIfInsightsExists) {
                const getFirstPage = (navigation && navigation?.length > 0) && navigation?.[0]
                setRedirectPartner(getFirstPage?.path)
            } else if (!AdminGuard()) {
                setRedirectPartner('/inventory')
            }
        }
        else if (!token && !redirectPartner) {
            setRedirectPartner('/login')
        }
    }, [navigation, token])

    return (
        <div className='App !font-dm_sansregular'>
            <Routes>
                {routes?.filter((item: any) => token && item?.key !== 'guest' ? findParentIfExist(item?.path) : true)?.map((route: any, index: number) => {
                    const children = (route?.childrens && route?.childrens?.length > 0 && token && navigation) ? route?.childrens?.filter((item: any) => item?.path === '' || findChildIfExist(route?.path, item?.path)) : route?.childrens
                    return (
                        children ? (
                            <React.Fragment key={index}>
                                {route.index && <Route
                                    path={route.path}
                                    element={<Navigate to={route.index} replace />}
                                />}
                                <Route path={route.path} element={<RouteWrapper guard={route.guard} element={route.element} />}>
                                    {children?.map((r: any, i: number) => {
                                        return (
                                            <Route key={i} path={r.path} element={<RouteWrapper guard={r.guard || route.guard} element={r.element} />} />
                                        )
                                    }
                                    )}
                                </Route>
                            </React.Fragment>
                        ) : (
                            <Route
                                key={index}
                                path={route.path}
                                element={<RouteWrapper guard={route.guard} element={route.element} />}
                            />
                        )
                    )
                }
                )}
                <Route path='*' element={<Navigate replace to={token ? redirectPartner || redirectNotExistingRoutes || '/login' : '/login'} />} />
            </Routes>
        </div>
    );
}

// export default App;
export default Sentry.withProfiler(App);