import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import _ from 'lodash';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { AdminGuard } from '../../guards/RolesGuard';
import { classNames } from '../../utils/classNames';
import { useGetCurrentUserQuery } from '../../store/user/userApi';
import { tryToFetchNavigationAccess } from '../../store/inventory/shared/partners/partnersSlice';

const navigationAgents = [
    { name: 'Inventory', href: '/inventory', icon: <img alt='home' src='/assets/navigation-icons/inventory.svg' />, iconActive: <img alt='inventory-active' src='/assets/navigation-icons/inventory-active.svg' />, enabled: true },
    { name: 'Stock Move', href: '/stock-move', icon: <img alt='stock' src='/assets/navigation-icons/stock-move.svg' />, iconActive: <img alt='stock-move-active' src='/assets/navigation-icons/stock-move-active.svg' />, enabled: true },
    // { name: 'Wingplus Orders', href: '/wingplus-orders', icon: <img alt='wingplus-orders' src='/assets/navigation-icons/wingplus-orders.svg' />, iconActive: <img alt='wingplus-orders-active' src='/assets/navigation-icons/wingplus-orders-active.svg' />, enabled: true },
    { name: 'Sale Requests', href: '/sale-requests', icon: <img alt='sale-requests' src='/assets/navigation-icons/sale-requests.svg' />, iconActive: <img alt='sale-requests-active' src='/assets/navigation-icons/sale-requests-active.svg' />, enabled: true, parent: 'Purchases' },
    { name: 'Purchase Orders', href: '/purchases', icon: <img alt='purchases' src='/assets/navigation-icons/purchases.svg' />, iconActive: <img alt='purchases-active' src='/assets/navigation-icons/purchases-active.svg' />, enabled: true, parent: 'Purchases' },
    { name: 'Purchase Requests', href: '/purchase-requests', icon: <img alt='purchase-requests' src='/assets/navigation-icons/purchase-requests.svg' />, iconActive: <img alt='purchase-requests-active' src='/assets/navigation-icons/purchase-requests-active.svg' />, enabled: true, parent: 'Sales' },
    { name: 'Sales Orders', href: '/sales-orders', icon: <img alt='sales' src='/assets/navigation-icons/sales.svg' />, iconActive: <img alt='sales-active' src='/assets/navigation-icons/sales-active.svg' />, enabled: true, parent: 'Sales' },
    { name: 'Credit Notes', href: '/credit-notes', icon: <img alt='credit-notes' src='/assets/navigation-icons/credit-notes.svg' />, iconActive: <img alt='credit-notes-active' src='/assets/navigation-icons/credit-notes-active.svg' />, enabled: true },
    { name: 'Reports', dataQa: 'reports-tab', href: '/reports-inventory', icon: <img alt='reports' src='/assets/navigation-icons/reports.svg' />, iconActive: <img alt='reports-active' src='/assets/navigation-icons/reports-black.svg' />, enabled: true },
]

const navigationAdmin = [
    { name: 'Sales', href: '/sales-admin', icon: <img alt='admin-sales' src='/assets/navigation-icons/sales.svg' />, iconActive: <img alt='sales-active' src='/assets/navigation-icons/sales-active.svg' />, enabled: true },
    { name: 'Sales Review', href: '/sales-review-admin', icon: <img alt='admin-sales-review' src='/assets/navigation-icons/sale-review.svg' />, iconActive: <img alt='sale-review-active' src='/assets/navigation-icons/sale-review-active.svg' />, enabled: true },
]

const extraNavigation = [
    { name: 'Profile', href: '/profile-inventory', icon: <img alt='profile' src='/assets/navigation-icons/profile.svg' />, iconActive: <img alt='profile-active' src='/assets/navigation-icons/profile-active.svg' />, enabled: true },
    { name: 'Locations Settings', href: '/warehouse-locations', icon: <img alt='warehouse-locations' src='/assets/navigation-icons/warehouse.svg' />, iconActive: <img alt='warehouse-active' src='/assets/navigation-icons/warehouse-active.svg' />, enabled: true },
    { name: 'Sales Form Settings', href: '/sales-form-settings', icon: <img alt='sales-form-settings' src='/assets/navigation-icons/settings.svg' />, iconActive: <img alt='setting-active' src='/assets/navigation-icons/settings-active.svg' />, enabled: true },
]

const onGetUserRole = (): string | null => {
    const { data } = useGetCurrentUserQuery();
    return data?.groups?.[0] || localStorage.getItem('roles');
};

const Navigation = () => {
    const location = useLocation();
    const dispatch = useAppDispatch();
    const isAdmin = AdminGuard()
    const userRole = onGetUserRole() || '';
    const [navigationPartner, setNavigationPartner] = useState<any>()
    const [activeRoute, setActiveRoute] = useState<string>()
    const [openParent, setOpenParent] = useState<string>()
    const [navigationAdminData, setNavigationAdminData] = useState<any>()
    const [navigationPartnerExtra, setNavigationPartnerExtra] = useState<any>()
    const [adminTempNavigation, setAdminTempNavigation] = useState<any>()
    const [loading, setLoading] = useState<boolean>(false)
    const navigationDetails = useAppSelector((state) => state.partners?.navigationAccess);

    const onFormatNavData = () => {
        if (!isAdmin) {
            const formatNav = (navigationDetails && navigationDetails?.length > 0) ? navigationDetails?.filter((item: any) => !item?.extras)?.map((item: any) => ({ name: item?.name, href: item?.path, enabled: true, icon: <img alt={item?.name} src={item?.icon} /> }))
                : navigationAgents
            const formatExtras = (navigationDetails && navigationDetails?.length > 0) ? navigationDetails?.filter((item: any) => item?.extras)?.map((item: any) => ({ name: item?.name, href: item?.path, enabled: true, icon: <img alt={item?.name} src={item?.icon} /> }))
                : extraNavigation
            setNavigationPartnerExtra(formatExtras || undefined)
            setNavigationPartner(formatNav || undefined)
            setAdminTempNavigation(undefined)
            setLoading(false)
        } else {
            const formatNav = (navigationDetails && navigationDetails?.length > 0) ? navigationDetails?.filter((item: any) => !item?.extras)?.map((item: any) => ({ name: item?.name, href: item?.path, enabled: true, icon: <img alt={item?.name} src={item?.icon} /> }))
                : navigationAdmin
            setLoading(false)
            setNavigationAdminData(formatNav)
        }
    }

    useEffect(() => {
        setActiveRoute(location.pathname)
    }, [location])

    useEffect(() => {
        if ((navigationDetails === null || navigationDetails) && (navigationAdminData || navigationPartner)) {
            setLoading(false)
        }
    }, [navigationAdminData, navigationPartner, navigationPartnerExtra, adminTempNavigation])

    useEffect(() => {
        onFormatNavData()
    }, [navigationDetails])

    const getNavData = async () => {
        await dispatch(tryToFetchNavigationAccess())
    }

    useEffect(() => {
        setLoading(true)
        getNavData()
    }, [])

    return (
        <>
            <p className='mb-3 text-[#8a8a8e]'>Menu</p>
            {isAdmin ? (
                <>
                    {(!loading && navigationAdminData && navigationAdminData?.length > 0)
                        && _(
                            _(adminTempNavigation || navigationAdminData)
                                .groupBy('parent')
                                .value()
                        )?.map((children: any, parent: string) => {
                            if (parent === 'undefined') {
                                return children.map((item: any) => ({ parent: null, children: [item], child: item?.name }));
                            } else {
                                return {
                                    parent: parent || null,
                                    children: children.map((item: any) => item)
                                };
                            }
                        })
                            .flatten()
                            .sortBy((obj: any) => {
                                if (obj.parent === null) {
                                    const index = (adminTempNavigation || navigationAdminData).findIndex((item: any) => item?.name === obj?.child);
                                    return index !== -1 ? index : (adminTempNavigation || navigationAdminData).length;
                                } else {
                                    const index = (adminTempNavigation || navigationAdminData).findIndex((item: any) => item.parent === obj.parent);
                                    return index !== -1 ? index : (adminTempNavigation || navigationAdminData).length;
                                }
                            })
                            .value()?.map((value: any) => {
                                const isAnyChildActive = (value?.children && value?.children?.length > 0) && value?.children?.find((item: any) => item?.href === activeRoute)
                                const isOpenParent = openParent === value?.parent
                                return (
                                    <div key={value.parent}>
                                        {value?.parent
                                            && <div
                                                className='cursor-pointer flex flex-row justify-between items-center px-2 py-2 mb-2'
                                                onClick={() => value?.parent && setOpenParent(value?.parent === openParent ? undefined : value?.parent)}>
                                                <div className='flex flex-row items-center'>
                                                    {(value?.children && value?.children?.length > 0)
                                                        && <div className='flex mr-1 flex-shrink-0 h-5 w-5 justify-center items-center'>{value?.children?.[0]?.icon}</div>
                                                    }
                                                    <p className='text-base text-secondary !font-dm_sansregular group flex items-center px-2 font-medium rounded-md "'>{value?.parent || ''}</p>
                                                </div>
                                                <img src={'/assets/navigation-icons/navigation-arrow.svg'} className={`object-contain w-3 transition ${(isAnyChildActive || isOpenParent) ? 'rotate-90' : 'rotate-0'}`} />
                                            </div>
                                        }
                                        {(isAnyChildActive || !value?.parent || isOpenParent) &&
                                            <div className={value?.parent ? 'ml-3 mb-2 transition' : ''}>
                                                {(value?.children && value?.children?.length > 0) && value?.children?.map((item: any) => {
                                                    return (
                                                        <NavLink
                                                            key={item.name}
                                                            data-qa={item?.dataQa}
                                                            to={item.href}
                                                            className={({ isActive }) =>
                                                                classNames(
                                                                    'text-base text-secondary group flex items-center px-2 py-2 font-medium rounded-md mb-2',
                                                                    item.enabled && isActive
                                                                        ? 'font-dm_sansbold !text-[#202020] bg-gradient-to-br from-[#FCEE21] to-[#F7931E] !rounded-lg'
                                                                        : 'font-dm_sansregular',
                                                                )
                                                            }
                                                        >
                                                            {/* <item.icon className='mr-3 flex-shrink-0 h-6 w-6 text-indigo-300' aria-hidden='true' /> */}
                                                            <span className='flex mr-2 flex-shrink-0 h-6 w-6 justify-center items-center'>
                                                                {activeRoute === item?.href ?
                                                                    React.cloneElement(item.iconActive || item.icon, {
                                                                        width: 20,
                                                                        height: 20,
                                                                    })
                                                                    : React.cloneElement(item.icon, {
                                                                        width: 20,
                                                                        height: 20,
                                                                    })
                                                                }
                                                            </span>
                                                            {item.name}
                                                        </NavLink>
                                                    )
                                                }
                                                )
                                                }
                                            </div>
                                        }
                                    </div>
                                )
                            })
                    }
                </>
            ) : (
                <>
                    {(!loading && navigationPartner && navigationPartner?.length > 0)
                        && _(
                            _(navigationPartner)
                                .groupBy('parent')
                                .value()
                        )?.map((children: any, parent: string) => {
                            if (parent === 'undefined') {
                                return children.map((item: any) => ({ parent: null, children: [item], child: item?.name }));
                            } else {
                                return {
                                    parent: parent || null,
                                    children: children.map((item: any) => item)
                                };
                            }
                        }).flatten()
                            .sortBy((obj: any) => {
                                if (obj.parent === null) {
                                    const index = (navigationPartner)?.findIndex((item: any) => item?.name === obj?.child);
                                    return index !== -1 ? index : (navigationPartner)?.length;
                                } else {
                                    const index = (navigationPartner)?.findIndex((item: any) => item.parent === obj.parent);
                                    return index !== -1 ? index : (navigationPartner)?.length;
                                }
                            })
                            .value()?.filter(
                                (item: any) => !item?.access || item.access === userRole,
                            )
                            .map((item: any) => {
                                const isAnyChildActive = (item?.children && item?.children?.length > 0) && item?.children?.find((child: any) => child?.href === activeRoute)
                                const isOpenParent = openParent === item?.parent
                                return (
                                    <div key={item.parent}>
                                        {item?.parent
                                            && <div
                                                className='cursor-pointer flex flex-row justify-between items-center px-2 py-2 mb-2'
                                                onClick={() => item?.parent && setOpenParent(item?.parent === openParent ? undefined : item?.parent)}>
                                                <div className='flex flex-row items-center'>
                                                    {(item?.children && item?.children?.length > 0)
                                                        && <div className='flex mr-1 flex-shrink-0 h-5 w-5 justify-center items-center'>{item?.children?.[0]?.icon}</div>
                                                    }
                                                    <p className='text-base text-secondary !font-dm_sansregular group flex items-center px-2 font-medium rounded-md'>{item?.parent || ''}</p>
                                                </div>
                                                <img src={'/assets/navigation-icons/navigation-arrow.svg'} className={`object-contain w-3 transition ${(isAnyChildActive || isOpenParent) ? 'rotate-90' : 'rotate-0'}`} />
                                            </div>
                                        }
                                        {(isAnyChildActive || !item?.parent || isOpenParent) &&
                                            <div className={item?.parent ? 'ml-2 mb-2 transition' : ''}>
                                                {(item?.children && item?.children?.length > 0) && item?.children?.map((value: any) => {
                                                    return (
                                                        <NavLink
                                                            key={value?.name}
                                                            to={value?.href}
                                                            data-qa={`${(value?.name).toLowerCase().replaceAll(' ', '-')}`}
                                                            className={({ isActive }) =>
                                                                classNames(
                                                                    'text-base text-secondary group flex items-center px-2 py-2 font-medium rounded-md mb-2',
                                                                    value?.enabled && isActive
                                                                        ? 'font-dm_sansbold !text-[#202020] bg-gradient-to-br from-[#FCEE21] to-[#F7931E] !rounded-lg'
                                                                        : 'font-dm_sansregular',
                                                                )
                                                            }
                                                        >
                                                            {/* <item.icon className='mr-3 flex-shrink-0 h-6 w-6 text-indigo-300' aria-hidden='true' /> */}
                                                            <span className='flex mr-2 flex-shrink-0 h-6 w-6 justify-center items-center'>
                                                                {activeRoute === value?.href ?
                                                                    React.cloneElement(value?.iconActive || value?.icon, {
                                                                        width: 20,
                                                                        height: 20,
                                                                    })
                                                                    : React.cloneElement(value?.icon, {
                                                                        width: 20,
                                                                        height: 20,
                                                                    })
                                                                }
                                                            </span>
                                                            {value?.name}
                                                        </NavLink>
                                                    )
                                                })}
                                            </div>
                                        }
                                    </div>
                                )
                            })}
                    <div className='mt-14'>
                        {!loading && navigationPartnerExtra?.map((item: any) => (
                            <NavLink
                                key={item.name}
                                to={item.href}
                                data-qa={`${(item?.name).toLowerCase().replaceAll(' ', '-')}`}
                                className={({ isActive }) =>
                                    classNames(
                                        'text-base text-secondary group flex items-center px-2 py-2 font-medium rounded-md mb-2',
                                        item.enabled && isActive
                                            ? 'font-dm_sansbold !text-[#202020] bg-gradient-to-br from-[#FCEE21] to-[#F7931E] !rounded-lg'
                                            : 'font-dm_sansregular',
                                    )
                                }
                            >
                                {/* <item.icon className='mr-3 flex-shrink-0 h-6 w-6 text-indigo-300' aria-hidden='true' /> */}
                                <span className='flex mr-2 flex-shrink-0 h-6 w-6 justify-center items-center'>
                                    {activeRoute === item?.href ?
                                        React.cloneElement(item.iconActive || item.icon, {
                                            width: 20,
                                            height: 20,
                                        })
                                        : React.cloneElement(item.icon, {
                                            width: 20,
                                            height: 20,
                                        })
                                    }
                                </span>
                                {item.name}
                            </NavLink>
                        ))}
                    </div>
                </>
            )
            }
        </>
    )
}

export default Navigation;