import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { IInventory } from '../../../../interfaces/inventory-data/IInventory';
import { IPage } from '../../../../interfaces/models/models/paginated-response.model';
import { IPaginationPayload } from '../../../../interfaces/shared/IPaginationPayload';
import { CURRENCY_SYMBOLS } from '../../../../utils/constants/currencySymbols';
import { VAT_ITEMS } from '../../../../utils/constants/vat-types';
import Button from '../../../../shared/button';
import Table from '../../../../shared/table';


interface IInventoryDetailsTable {
    rows: IInventory[];
    sources: Array<any>;
    paginationData?: IPage;
    inventoryLoading?: boolean;
    accessControl?: any;
    actions?: any;
    onChangeLocation: (id: string) => void;
    setPaginationState: ({ pageNumber, pageSize }: IPaginationPayload) => void;
    onClickSeeDetails: (id: string) => void;
}

const InventoryDetailsTable = ({
    rows,
    sources,
    paginationData,
    accessControl,
    actions,
    onChangeLocation,
    setPaginationState,
    onClickSeeDetails
}: IInventoryDetailsTable) => {
    const [tableLoading, setTableLoading] = useState<boolean>(false)
    const [tableColumns, setTableColumns] = useState<Array<string>>(['Item ID', 'Item Name', 'Purchase Cost', 'Additional Cost', 'Total Cost', 'Reserved', 'Stock VAT', 'Source', 'Booked Date', 'Status', 'Inventory Location', 'Location'])
    const [tableExtraKeys, setTableExtraKeys] = useState<Array<any>>()

    const capitalizeFirstLetter = (word: string) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
    }

    const formatTableColumnsAndKeys = () => {
        setTableLoading(true)
        const allKeysArray: any = (rows && rows?.length > 0) && rows.flatMap(obj =>
            Object.keys(obj?.exchangeDefinition?.asset?.descriptor || {})
        );
        const findKeys = (allKeysArray && allKeysArray?.length > 0) && allKeysArray?.filter((key: any, index: any, array: any) => array?.indexOf(key) === index);
        const findInventoryKeys = (rows && rows?.length > 0 && rows?.[0] && rows?.[0]?.inventory ) ? Object?.keys(rows?.[0]?.inventory) : undefined
        const formatTableColumns = []
        const formatTableExtraKeys: any = []
        formatTableColumns.push('Item ID')
        formatTableColumns.push('Item Name')
        formatTableColumns.push('Purchase Cost')
        formatTableColumns.push('Additional Cost')
        formatTableColumns.push('Total Cost')
        if (findKeys && findKeys?.length > 0) {
            findKeys?.map((key: string) => {
                const formatKey = capitalizeFirstLetter(key)
                const formatElement = (formatKey || '').replaceAll('_', ' ')
                formatTableExtraKeys.push(key)
                formatTableColumns.push(formatElement)
                return key
            })
        } else {
            formatTableColumns.push('Condition')
        }
        if (findInventoryKeys && findInventoryKeys?.length > 0) {
            findInventoryKeys && findInventoryKeys?.length > 0 && findInventoryKeys?.map((key: string) => {
                if ((key || '').toLowerCase() === 'sales_grade') {
                    formatTableExtraKeys.push(key)
                    formatTableColumns.push('Sales Grade')
                }
                else {
                    const formatKey = capitalizeFirstLetter(key)
                    const formatElement = (formatKey || '').replaceAll('_', ' ')
                    formatTableExtraKeys.push(key)
                    formatTableColumns.push(formatElement)
                }
                return key
            })
        }
        formatTableColumns.push('Reserved')
        formatTableColumns.push('Stock VAT')
        formatTableColumns.push('Source')
        formatTableColumns.push('Booked Date')
        formatTableColumns.push('Status')
        formatTableColumns.push('Inventory Location')
        formatTableColumns.push('Location')
        setTableColumns(formatTableColumns || [])
        setTableExtraKeys(formatTableExtraKeys || [])
    }

    useEffect(() => {
        setTableLoading(true)
        formatTableColumnsAndKeys()
    }, [rows, paginationData])

    useEffect(() => {
        setTableLoading(true)
        formatTableColumnsAndKeys()
    }, [])

    useEffect(() => {
        if (tableExtraKeys) {
            setTableLoading(false)
        }
    }, [tableExtraKeys])

    return (
        <Table
            columns={tableColumns}
            paginationData={paginationData}
            setPaginationState={setPaginationState}
            containerStyle={'mt-0'}>
            {(!tableColumns || tableLoading) ?
                <>
                    {[...Array(4)].map((x, i) => {
                        return (
                            <tr key={i}>
                                {[...Array(tableColumns?.length || 1)].map((x, j) => {
                                    return (
                                        <td key={j} className='td-1 !min-w-[100%] text-center py-5'><span></span></td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                </>
                : <>
                    {rows?.length > 0 && rows?.map((row: IInventory, idx: number) => {
                        return (
                            <tr key={idx} className='bg-white border-b hover:bg-gray-50'>
                                <td className='px-6 py-4' >
                                    <div
                                        className='underline cursor-pointer text-[#F7A21E] hover:opacity-95'
                                        onClick={() => (!accessControl || actions?.['See Item Details']) && onClickSeeDetails(row?.itemCode || row?.itemId)}>
                                        {row?.itemCode || row?.itemId}
                                    </div>
                                </td>
                                <td className='px-6 py-4 min-w-[150px]'>{row?.itemName}</td>
                                <td className='px-6 py-4'>
                                    {CURRENCY_SYMBOLS?.[`${(row?.currency || '').toLowerCase()}`]}{Number(row?.revaluatedPrice || row?.price || 0).toFixed(2)}
                                </td>
                                <td className='px-6 py-4'>
                                    {CURRENCY_SYMBOLS?.[`${(row?.currency || '').toLowerCase()}`]}{Number(row?.additionalCost || 0).toFixed(2)}
                                </td>
                                <td className='px-6 py-4'>
                                    {CURRENCY_SYMBOLS?.[`${(row?.currency || '').toLowerCase()}`]}{Number(row?.totalPrice || 0).toFixed(2)}
                                </td>
                                {tableExtraKeys && tableExtraKeys?.length > 0 ? tableExtraKeys?.map((key: string, index: number) => {
                                    return (
                                        <td
                                            key={index}
                                            className={'px-6 py-4'}>
                                            {row?.inventory?.[key] || (row?.revaluation ? row?.revaluation?.[key] : row?.exchangeDefinition?.asset?.descriptor?.[key])}
                                        </td>
                                    )
                                }) :
                                    <td
                                        className={'px-6 py-4'}>
                                        {row?.condition || ''}
                                    </td>
                                }
                                <td className='px-6 py-4'>{row?.reservedSaleId ? 'Yes' : 'No'}</td>
                                <td className='px-6 py-4'>{row?.stockVAT ? VAT_ITEMS?.find((item: any) => item?.value === row?.stockVAT)?.label || row?.stockVAT : ''}</td>
                                <td className='px-6 py-4'>{row?.source ? sources?.find((item: any) => item?.name === row?.source)?.label || row?.source : ''}</td>
                                <td className='px-6 py-4'>{row?.bookedIn && moment(row?.bookedIn).fromNow()}</td>
                                <td className='px-6 py-4 min-w-[150px]'>{row?.orderStatus || ''}</td>
                                <td className='px-6 py-4'>
                                    {row?.locations && (Object?.keys(row?.locations))?.map((loc: any, index: number) => {
                                        return `${index === 0 ? `${row?.locations?.[loc]}` : ` - ${row?.locations?.[loc]}`}`
                                    })}
                                </td>
                                <td className='px-2 py-2'>
                                    <Button
                                        label={'Change Location'}
                                        className={`${(!accessControl || actions?.['Change Location']) ? 'btn-primary-rounded-less' : 'btn-primary-rounded-less-disable'} text-[13px]`}
                                        onClickButton={() => row?.itemId && (!accessControl || actions?.['Change Location']) && onChangeLocation(row?.itemId)}
                                    />
                                </td>
                            </tr>
                        )
                    })}
                </>
            }
        </Table>
    )
}

export default InventoryDetailsTable;