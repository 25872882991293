import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone'
import Papa from 'papaparse';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { tryToImportInventoryData } from '../../../../store/inventory/broker/inventory-data/inventoryDataSlice';
import SuccessfullyUploadFile from '../details/SuccessfullyUploadedFile';
import UploadCsvFile from '../details/UploadCsvFile';
import Modal from '../../../../shared/modal';

interface IImportInventoryModal {
    openImportModal: boolean;
    sectors?: Array<any>;
    markets?: Array<any>;
    handleCloseModal: () => void;
}

const ImportInventoryModal = ({
    openImportModal,
    sectors,
    markets,
    handleCloseModal
}: IImportInventoryModal) => {
    const dispatch = useAppDispatch();
    const [importError, setImportError] = useState<string>()
    const [importedSuccessfully, setImportedSuccessfully] = useState<boolean>(false)
    const [fileSelected, setFileSelected] = useState<any>()
    const [selectedCategory, setSelectedCategory] = useState<any>()
    const [selectedMarket, setSelectedMarket] = useState<any>()
    const activeWarehouse = useAppSelector((state) => state?.partners?.partnerActiveWarehouse)

    const onDrop = useCallback((acceptedFiles: any) => {
        setFileSelected(acceptedFiles?.[0])
    }, [])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    const onCloseModal = () => {
        handleCloseModal();
        setImportedSuccessfully(false);
        setImportError(undefined);
        setSelectedMarket(undefined)
        setSelectedCategory(undefined)
        setFileSelected(undefined)
    }

    const onUploadFile = async () => {
        if (!selectedCategory || !selectedMarket) {
            setImportError('Please select market and category first to continue.')
            return;
        }
        try {
            Papa.parse(fileSelected, {
                header: true,
                skipEmptyLines: true,
                complete: async function (results: any) {
                    const payload = {
                        marketId: selectedMarket?._id,
                        assetTemplateId: selectedCategory?.id,
                        warehouseId: activeWarehouse?._id,
                        data: results?.data || []
                    }
                    await dispatch(tryToImportInventoryData(payload)).unwrap()
                }
            })
            setImportError(undefined)
            setImportedSuccessfully(true)
        } catch (err) {
            setImportedSuccessfully(false)
            setImportError(`${err}`)
        }
    }

    const onChangeSelectedSector = (value: any, type?: string) => {
        setSelectedCategory(value)
    }

    const onChangeSelectedMarket = (value: any, type?: string) => {
        setSelectedMarket(value)
    }

    return (
        <Modal
            open={openImportModal}
            onClose={onCloseModal}>
            <div className={'p-2 min-w-[500px]'}>
                {importedSuccessfully ?
                    <SuccessfullyUploadFile
                        handleCloseModal={onCloseModal}
                    />
                    : <UploadCsvFile
                        onUploadFile={onUploadFile}
                        getInputProps={getInputProps}
                        getRootProps={getRootProps}
                        isDragActive={isDragActive}
                        fileSelected={fileSelected}
                        importError={importError}
                        markets={markets || []}
                        sectors={sectors || []}
                        selectedSector={selectedCategory}
                        selectedMarket={selectedMarket}
                        activeWarehouse={activeWarehouse}
                        onChangeSelectedSector={onChangeSelectedSector}
                        onChangeSelectedMarket={onChangeSelectedMarket}
                    />
                }
            </div>
        </Modal>
    )
}

export default ImportInventoryModal;