import React from 'react';
import { AdminGuard, GuestGuard, PartnerAgentGuard } from '../guards/RolesGuard';
import Layout from '../container/Layout';
import Login from '../features/auth/login';
import Register from '../features/auth/register';
import Profile from '../features/dashboard/profile';
import Inventory from '../features/dashboard/inventory';
import Purchases from '../features/dashboard/purchases';
import StockMove from '../features/dashboard/stock-move';
import SaleAssets from '../features/admin-dashboard/sales';
import ResetPassword from '../features/auth/reset-password';
import SalesOrders from '../features/dashboard/sales-orders';
import CreditNotes from '../features/dashboard/credit-notes';
import SaleRequests from '../features/dashboard/sale-requests';
import SalesReview from '../features/admin-dashboard/sales-review';
// import WingplusOrders from '../features/dashboard/wingplus-orders';
import PurchaseDetails from '../features/dashboard/purchases/details';
import PurchaseRequests from '../features/dashboard/purchase-requests';
import InventoryDetails from '../features/dashboard/inventory/details';
import PurchaseForm from '../features/dashboard/purchases/purchase-form';
import SaleOrderDetails from '../features/dashboard/sales-orders/details';
import SaleFieldsDetails from '../features/admin-dashboard/sales/details';
import SalesManageFields from '../features/dashboard/sales/manage-fields';
import SaleOrderForm from '../features/dashboard/sales-orders/sales-form';
import WarehouseLocations from '../features/dashboard/warehouse-locations';
import SaleDetails from '../features/admin-dashboard/sales-review/details';
import CreditNoteDetails from '../features/dashboard/credit-notes/details';
import InventoryItemDetails from '../features/dashboard/inventory/item-details';
// import WingplusOrderDetails from '../features/dashboard/wingplus-orders/details';
import PurchaseReview from '../features/dashboard/purchases/purchase-review-new';
import WarehouseManage from '../features/dashboard/profile/categories/WarehouseManage';
import SectionDetails from '../features/dashboard/warehouse-locations/section-details';
import AdminReports from '../features/admin-dashboard/reports';

export interface RouteDefinition {
    path: string;
    element: JSX.Element;
    guard?: () => boolean;
    index?: string,
    childrens?: RouteDefinition[];
}

export const routes: RouteDefinition[] = [
    // Guest pages should have GuestGuard so everyone can access
    {
        path: '/login',
        element: <Login />,
        guard: GuestGuard,
    },
    {
        path: '/register',
        element: <Register />,
        guard: GuestGuard,
    },
    {
        path: '/reset-password',
        element: <ResetPassword />,
        guard: GuestGuard,
    },
    {
        path: '/sales-admin',
        element: <Layout />,
        index: '/sales-admin',
        guard: AdminGuard,
        childrens: [
            {
                path: '',
                element: <SaleAssets />
            },
            {
                path: ':id',
                element: <SaleFieldsDetails />
            },
        ],
    },
    {
        path: '/sales-review-admin',
        element: <Layout />,
        index: '/sales-review-admin',
        guard: AdminGuard,
        childrens: [
            {
                path: '',
                element: <SalesReview />
            },
            {
                path: ':id',
                element: <SaleDetails />
            }
        ],
    },
    // Brokers/Partners should have PartnerAgentGuard for each different role user should be a guard
    {
        path: '/inventory',
        element: <Layout />,
        index: '/inventory',
        guard: PartnerAgentGuard,
        childrens: [
            {
                path: '',
                element: <Inventory />
            },
            {
                path: ':assetId/:id',
                element: <InventoryDetails />
            },
            {
                path: 'details/:id',
                element: <InventoryItemDetails />
            }
        ],
    },
    {
        path: '/reports-inventory',
        element: <Layout />,
        index: '/reports-inventory',
        guard: PartnerAgentGuard,
        childrens: [
            {
                path: '',
                element: <AdminReports />,
            },
        ]
    },
    // {
    //     path: '/wingplus-orders',
    //     element: <Layout />,
    //     index: '/wingplus-orders',
    //     guard: PartnerAgentGuard,
    //     childrens: [
    //         {
    //             path: '',
    //             element: <WingplusOrders />
    //         },
    //         {
    //             path: ':id',
    //             element: <WingplusOrderDetails />
    //         }
    //     ],
    // },
    {
        path: '/sales-orders',
        element: <Layout />,
        index: '/sales-orders',
        guard: PartnerAgentGuard,
        childrens: [
            {
                path: '',
                element: <SalesOrders />
            },
            {
                path: ':id',
                element: <SaleOrderDetails />
            },
            {
                path: 'create',
                element: <SaleOrderForm />
            },
            {
                path: 'edit/:id',
                element: <SaleOrderForm />
            },
            {
                path: 'create/:id',
                element: <SaleOrderForm />
            },
        ]
    },
    {
        path: '/purchases',
        element: <Layout />,
        index: '/purchases',
        guard: PartnerAgentGuard,
        childrens: [
            {
                path: '',
                element: <Purchases />
            },
            {
                path: ':id',
                element: <PurchaseDetails />
            },
            {
                path: 'form/',
                element: <PurchaseForm />
            },
            {
                path: 'form/:id',
                element: <PurchaseForm />
            },
            {
                path: ':id/review/',
                element: <PurchaseReview />
            }
        ],
    },
    {
        path: '/purchase-requests',
        element: <Layout />,
        index: '/purchase-requests',
        guard: PartnerAgentGuard,
        childrens: [
            {
                path: '',
                element: <PurchaseRequests />
            }
        ],
    },
    {
        path: '/sale-requests',
        element: <Layout />,
        index: '/sale-requests',
        guard: PartnerAgentGuard,
        childrens: [
            {
                path: '',
                element: <SaleRequests />
            }
        ],
    },
    {
        path: '/credit-notes',
        element: <Layout />,
        index: '/credit-notes',
        guard: PartnerAgentGuard,
        childrens: [
            {
                path: '',
                element: <CreditNotes />
            },
            {
                path: ':id',
                element: <CreditNoteDetails />
            }
        ],
    },
    {
        path: '/stock-move',
        element: <Layout />,
        index: '/stock-move',
        guard: PartnerAgentGuard,
        childrens: [
            {
                path: '',
                element: <StockMove />
            }
        ],
    },
    {
        path: '/profile-inventory',
        element: <Layout />,
        index: '/profile-inventory',
        guard: PartnerAgentGuard,
        childrens: [
            {
                path: '',
                element: <Profile />
            },
            {
                path: 'warehouse/:id',
                element: <WarehouseManage />
            }
        ],
    },
    {
        path: '/warehouse-locations',
        element: <Layout />,
        index: '/warehouse-locations',
        guard: PartnerAgentGuard,
        childrens: [
            {
                path: '',
                element: <WarehouseLocations />
            },
            {
                path: 'section/:id',
                element: <SectionDetails />
            }
        ],
    },
    {
        path: '/sales-form-settings',
        element: <Layout />,
        index: '/sales-form-settings',
        guard: PartnerAgentGuard,
        childrens: [
            {
                path: '',
                element: <SalesManageFields />
            }
        ],
    },
];
