import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { authApi } from '../store/auth/authApi';
import { userAPI } from '../store/user/userApi';
import { userDomAPI } from '../store/user/userDomApi';
import { addressCheckDomAPI } from '../store/inventory/broker/address-check/addressCheckDomApi';
import salesSlice from '../store/inventory/shared/sales/salesSlice';
import crmSlice from '../store/inventory/broker/crm-data/crmDataSlice';
import partnersSlice from '../store/inventory/shared/partners/partnersSlice';
import purchasesSlice from '../store/inventory/broker/purchases/purchasesSlice';
import warehousesSlice from '../store/inventory/broker/warehouses/warehousesSlice';
import creditNotesSlice from '../store/inventory/broker/credit-notes/creditNotesSlice';
import itemIdSearchSlice from '../store/inventory/shared/item-search/itemIdSearchSlice';
import saleRequestsSlice from '../store/inventory/broker/sale-requests/saleRequestsSlice';
import inventoryDataSlice from '../store/inventory/broker/inventory-data/inventoryDataSlice';
import wingplusOrdersSlice from '../store/inventory/broker/wingplus-orders/wingplusOrdersSlice';
import assetTemplatesSlice from '../store/inventory/shared/assets-templates/assetsTemplatesSlice';
import purchaseRequestsSlice from '../store/inventory/broker/purchase-requests/purchaseRequestsSlice';
import warehouseLocationSlice from '../store/inventory/broker/warehouse-locations/warehouseLocationsSlice';
import questionsSlice from '../store/questions/questionsSlice';
import reportsSlice from '../store/inventory/broker/reports/reportsServiceSlice';


export const store = configureStore({
    reducer: {
        [userAPI.reducerPath]: userAPI.reducer,
        [authApi.reducerPath]: authApi.reducer,
        [userDomAPI.reducerPath]: userDomAPI.reducer,
        [addressCheckDomAPI.reducerPath]: addressCheckDomAPI.reducer,
        assetsTemplates: assetTemplatesSlice,
        inventory: inventoryDataSlice,
        warehouses: warehousesSlice,
        partners: partnersSlice,
        sales: salesSlice,
        crm: crmSlice,
        purchases: purchasesSlice,
        reports: reportsSlice,
        warehouseLocations: warehouseLocationSlice,
        imeiSearch: itemIdSearchSlice,
        wingplusOrders: wingplusOrdersSlice,
        creditNotes: creditNotesSlice,
        purchaseRequests: purchaseRequestsSlice,
        saleRequests: saleRequestsSlice,
        questions: questionsSlice
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false
        }).concat(
            userAPI.middleware,
            authApi.middleware,
            userDomAPI.middleware,
            addressCheckDomAPI.middleware
        ),
})

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;