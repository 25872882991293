import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../../app/hooks';
import { SALES_PAID_TYPES } from '../../../../utils/constants/sales-paid-types';
import { ISelectedValue } from '../../../../interfaces/components/ISelectedValue';
import { tryToEditSaleStatus } from '../../../../store/inventory/shared/sales/salesSlice';
import { tryToFetchAllCreditNotes } from '../../../../store/inventory/broker/credit-notes/creditNotesSlice';
import SelectCheckbox from '../../../../shared/select-checkbox';
import Button from '../../../../shared/button';
import Modal from '../../../../shared/modal';
import Error from '../../../../shared/error';
import Input from '../../../../shared/input';


interface IMarkAsPaidModal {
    openModal: boolean;
    saleId: string;
    partnerId?: string;
    handleCloseModal: () => void;
    onSuccessfullyChanged: (type: string, message: string) => void;
}

const MarkAsPaidModal = ({
    openModal,
    saleId,
    partnerId,
    handleCloseModal,
    onSuccessfullyChanged,
}: IMarkAsPaidModal) => {
    const dispatch = useAppDispatch();
    const [amount, setAmount] = useState<any>();
    const [error, setError] = useState<string>();
    const [paidType, setPaidType] = useState<string>();
    const [creditNotes, setCreditNotes] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedCreditNote, setSelectedCreditNote] = useState<ISelectedValue>();

    const onGettingAllCreditNotes = async () => {
        try {
            const response = await dispatch(tryToFetchAllCreditNotes({ customerId: partnerId })).unwrap();
            const formatCreditNotes = (response && response?.length > 0) && response?.map((item: any) => ({ ...item || {}, value: item?._id, label: item?.creditNoteNumber || item?._id }))
            setCreditNotes(formatCreditNotes || [])
        } catch (err) {
            // err here
        }
    }

    useEffect(() => {
        onGettingAllCreditNotes()
    }, [openModal, saleId])

    const onCloseModal = () => {
        setError(undefined)
        setLoading(false)
        handleCloseModal()
    }

    const onApproveAction = async () => {
        setLoading(true)
        if (!paidType) {
            setError('Please select paid method!')
            setLoading(false)
            return;
        }
        if (paidType === 'cash' && !amount && amount !== 0) {
            setError('Please write amount paid!')
            setLoading(false)
            return;
        }
        if (paidType === 'credit_note' && !selectedCreditNote) {
            setError('Please select credit note!')
            setLoading(false)
            return;
        }
        if (paidType === 'cash_and_credit_note' && (!selectedCreditNote || !amount)) {
            setError('Please select credit note and write amount!')
            setLoading(false)
            return;
        }
        try {
            const formatData = {
                type: paidType,
                creditNote: paidType !== 'cash' ? selectedCreditNote?.value : undefined,
                amount: paidType !== 'credit_note' ? amount : undefined
            }
            await dispatch(tryToEditSaleStatus({ id: saleId, status: 'paid', data: formatData })).unwrap()
            onSuccessfullyChanged('success', 'Sale Order marked as paid successfully!')
            onCloseModal();
        } catch (err) {
            setError(`${err}`)
        }
        setLoading(false)
    }

    const onClickPaidType = (value: string) => {
        setPaidType((prevPaidType) => (prevPaidType === value ? undefined : value))
    }

    const onChangeAmount = (value: any, type?: string) => {
        setAmount((/^\d*\.?\d*$/).test(value) ? value : amount)
    }

    const onChangeSelectedCreditNote = (value: ISelectedValue, type?: string) => {
        setSelectedCreditNote(value)
    }

    return (
        <Modal
            open={openModal}
            showInRight={true}
            title='Mark as Paid'
            onClose={() => onCloseModal()}>
            <div className={'p-2 min-w-[500px]'}>
                <div className='border-b-2 border-slate-200 pb-2 my-1'>
                    <p className='font-medium text-slate-700'>Action Verification</p>
                </div>
                <div className='bg-[#f8f9fc] rounded-[6px] p-3'>
                    <p className='my-2'>Do you want to mark this sales order as paid Sale?</p>
                    {error &&
                        <div className='flex flex-row justify-center my-5'>
                            <Error text={error} />
                        </div>
                    }
                    {SALES_PAID_TYPES?.map((item: { value: string, label: string }, index: number) => {
                        return (
                            <div key={index} className='grid grid-cols-3 gap-3 p-2 bg-white mb-1 !rounded'>
                                <div className='col-span-2'>
                                    <p>{item?.label}</p>
                                </div>
                                <div>
                                    <div className='flex items-center'>
                                        <label className='relative flex items-center p-1 rounded-full cursor-pointer' htmlFor='amber'>
                                            <input
                                                name='color'
                                                type='radio'
                                                onClick={() => onClickPaidType(item?.value)}
                                                value={item?.value}
                                                checked={paidType === item?.value}
                                                className={'before:content[\'\'] peer relative h-5 w-5 cursor-pointer appearance-none rounded-full border border-blue-gray-200 text-amber-500 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-amber-500 checked:before:bg-amber-500 hover:before:opacity-10'}
                                                id='amber' />
                                            <span
                                                className='absolute transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-amber-500 peer-checked:opacity-100'>
                                                <svg xmlns='http://www.w3.org/2000/svg' className='h-3 w-3' viewBox='0 0 16 16' fill='currentColor'>
                                                    <circle data-name='ellipse' cx='8' cy='8' r='8'></circle>
                                                </svg>
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                    {paidType &&
                        <div className='my-5'>
                            {paidType !== 'credit_note' &&
                                <Input
                                    placeholder='Set Cash Amount'
                                    dataQa={'amount'}
                                    showValue={true}
                                    inputValue={amount}
                                    onChangeInput={onChangeAmount}
                                // inputStyle={'max-w-[90%]'}
                                />
                            }
                            {paidType !== 'cash' &&
                                <SelectCheckbox
                                    placeholder='Select Credit Note'
                                    dataQa={'credit-note'}
                                    selectedOption={selectedCreditNote}
                                    options={creditNotes || []}
                                    onChangeSelectedOption={onChangeSelectedCreditNote}
                                />
                            }
                        </div>
                    }
                    <div className='flex flex-row justify-center my-2'>
                        <Button
                            label='No'
                            dataQa={'no-button'}
                            className={`${loading ? 'btn-main-disable' : 'btn-main !bg-white !border-0'} !rounded mr-3 min-w-[150px]`}
                            onClickButton={() => !loading && onCloseModal()}
                        />
                        <Button
                            label='Yes'
                            dataQa={'continue-button'}
                            className={`${loading ? 'btn-primary-disable !rounded' : 'btn-primary-rounded-less'} min-w-[150px]`}
                            onClickButton={() => !loading && onApproveAction()}
                        />
                    </div>
                </div>
            </div>
        </Modal >
    )
}
export default MarkAsPaidModal;